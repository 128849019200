import React from "react";
import SimpleTextInput from "components/inputs/simpleTextInput";
import { Row, Col, Tabs } from "antd";
import RadioButton from "components/inputs/radioButton";

const { TabPane } = Tabs;

function Form({
  name,
  phone,
  status,
  department,
  handleChange,
  tabActiveKey,
  access_cards,
  rfidChange,
}) {
  return (
    <React.Fragment>
      <Tabs
        type="card"
        activeKey={tabActiveKey}
        onChange={(value) =>
          handleChange({ target: { value, name: "tabActiveKey" } })
        }
        style={{ overflow: "visible" }}
      >
        <TabPane tab="Details" key="Details">
          <Row style={{ padding: "10px" }}>
            <Col xs={24} sm={12} md={12} style={{ padding: "5px 10px" }}>
              <SimpleTextInput
                labelText="Name"
                name="name"
                value={name}
                onChange={handleChange}
              />
            </Col>
            <Col item xs={24} sm={12} md={12} style={{ padding: "5px 10px" }}>
              <SimpleTextInput
                labelText="Phone"
                name="phone"
                value={phone}
                onChange={handleChange}
              />
            </Col>
            <Col item xs={24} sm={12} md={12} style={{ padding: "10px" }}>
              <SimpleTextInput
                labelText="Department"
                name="department"
                value={department}
                onChange={handleChange}
              />
            </Col>
            <Col item xs={24} sm={12} md={12} style={{ padding: "10px" }}>
              <RadioButton
                labelText="Status"
                value={status}
                data={{ Active: "active", Inactive: "inactive" }}
                onChange={handleChange}
                name="status"
                color={true}
              />
            </Col>
          </Row>
        </TabPane>

        <TabPane
          tab="AccessCard"
          key="AccessCard"
          style={{ maxHeight: 350, overflow: "auto" }}
        >
          {access_cards?.map((card, i) => {
            return (
              <Row style={{ padding: "5px 10px 5px 0px" }} key={i}>
                <Col xs={24} sm={16} style={{ padding: "12px 15px 5px 5px" }}>
                  <SimpleTextInput
                    name="rfid"
                    value={card.rfid}
                    onChange={(e) => rfidChange(e, i)}
                    labelText="Access Card"
                    readOnly={card.id}
                  />
                </Col>
                <Col xs={20} sm={8} style={{ padding: "17px 0px 0px 6px" }}>
                  <RadioButton
                    labelText=""
                    value={card.status}
                    onChange={(e) => rfidChange(e, i)}
                    data={{ Active: "active", Block: "inactive" }}
                    defaultValue="active"
                    name="status"
                    color={true}
                    width={80}
                  />
                </Col>
              </Row>
            );
          })}
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
}
export default Form;

import React, { Component } from "react";
import { Modal, Descriptions } from "antd";
import { IoMdOpen } from "react-icons/io";
import { presence } from "helpers";
export default class ShowStaffDetails extends Component {
  render() {
    const { visible, data, routeHandler, handleCancel } = this.props;
    const name = data.staff?.name;
    const imageUrl = data?.face_id_image?.s3_image_path;
    const phone = data.staff?.phone;
    const department = data.staff?.department;

    return (
      <Modal
        title="Staff Entry Details"
        centered
        visible={visible}
        width={700}
        footer={false}
        onCancel={handleCancel}
      >
        {/* image hidden  */}
        {imageUrl && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "50%",
            }}
          >
            <img style={{ width: "90%" }} src={imageUrl} alt="staff pic" />
          </div>
        )}

        {data.staff && (
          <Descriptions
            layout="horizontal"
            bordered
            colon
            column={2}
            style={{
              marginBottom: "30px",
              fontSize: "18px",
              fontWeight: "normal",
              padding: "0px 24px",
            }}
          >
            <Descriptions.Item label={"Name"}>
              {presence(name)}
            </Descriptions.Item>
            <Descriptions.Item label={"Phone"}>
              {presence(phone)}
              {phone && (
                <IoMdOpen
                  onClick={routeHandler}
                  style={{
                    color: "blue",
                    fontSize: "22px",
                    cursor: "pointer",
                    marginLeft: "1rem",
                  }}
                />
              )}
            </Descriptions.Item>
            <Descriptions.Item label={"Department"}>
              {presence(department)}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Modal>
    );
  }
}

import AppBar from "components/AppBar";
import React, { Component } from "react";
import HeadPages from "./HeadPages";
import { Skeleton } from "antd";

class AboutStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      skeleton: false,
      loading: false,
    };
  }

  render() {
    const { skeleton } = this.state;
    const props_data = this.props;
    let staffData = this.props.location.state?.row;
    let staffImage = staffData?.s3_image_path;
    let staffId = staffData?.id;
    let url = window.location.href;
    let splitUrl = url.split("/");
    let phoneUrl = splitUrl[4];
    let phone = this.props.match.params.phone;
    const formProps = {
      staffImage,
      staffId,
      phone,
      phoneUrl,
      props_data,
    };

    return (
      <React.Fragment>
        <AppBar />
        {skeleton === false ? (
          <HeadPages {...formProps} />
        ) : (
          <Skeleton
            style={{ width: "100%", padding: "10px 50px" }}
            active={true}
            title={false}
            paragraph={{
              rows: 10,
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default AboutStaff;

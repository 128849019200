import React, { Component } from "react";
import AppBar from "components/AppBar";
import moment from "moment";
import { columns } from "data/staffEntry";
import { Col } from "antd";
import { withRouter } from "react-router-dom";
import PaginationTable from "components/paginationTables/withAddEdit";
import ShowStaffDetails from "./ShowStaffDetails";
import { fetchStaffEntry } from "api/staffs";

class StaffEntry extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      page: 1,
      skeleton: true,
      visible: false,
      rowData: {},
    };
  }

  initialRequestParams = () => {
    return {
      fromTime: moment.utc(moment().startOf("day")).format(),
      toTime: moment.utc(moment().endOf("day")).format(),
      perPage: 25,
      page: 1,
    };
  };

  componentDidMount = () => {
    this.fetchTableData();
  };

  fetchTableData = () => {
    fetchStaffEntry(this.requestParams).then(({ data, total_entries }) => {
      this.setState({
        data: data,
        total_entries: total_entries,
        skeleton: false,
      });
    });
  };

  onDateChange = (from_time, to_time) => {
    this.requestParams.fromTime = from_time;
    this.requestParams.toTime = to_time;
    this.requestParams.page = 1;
    this.fetchTableData();
  };

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.fetchTableData();
  };

  render() {
    const { data, visible, rowData } = this.state;

    const { onChange, onDateChange } = this;

    const tableProps = {
      tabColor: "success",
      title: "Staff Entry Logs",
      subTitle: "Staff Entry from vizitin records can be monitored here",
      columns: columns,
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      onRowClick: (row) => {
        this.setState({
          visible: false,
          rowData: row,
        });
      },
    };
    const appBarProps = {
      fromTime: moment(this.requestParams.fromTime),
      toTime: moment(this.requestParams.toTime),
      search: this.requestParams.search,
      onChange,
      onDateChange,
    };
    const handleCancel = () => {
      this.setState({
        visible: !visible,
      });
    };
    const routeHandler = () => {
      this.props.history.push(`/staffs/${rowData.staff.phone}`);
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <ShowStaffDetails
          data={rowData}
          visible={visible}
          handleCancel={handleCancel}
          routeHandler={routeHandler}
        />
        <Col>
          <PaginationTable {...tableProps} />
        </Col>
      </React.Fragment>
    );
  }
}

export default withRouter(StaffEntry);

import React, { Component } from "react";
import AppBar from "components/AppBar";
import PaginationTable from "components/paginationTables/withAddEdit";
import { fetchStaffsData, aggregation } from "api/staffs";
import { columns } from "data/staffs";
import { Row } from "antd";
import {
  debounce,
  createAccess,
  updateAccess,
  uploadAccess,
  popCookie,
  setCookie,
  getCookie,
} from "helpers";
import { handleResponse } from "components/notifications/handleResponse";
import CRUDStaffs from "components/form/staffs/CRUD";
import CRUDUpload from "components/form/staffs/CRUDUpload";

class Staffs extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      name: [],
      departments: [],
      department: [],
      phone: [],
      phone_nos: [],
      staffsname: [],
      total_entries: 0,
      formOpen: false,
      rowData: undefined,
      skeleton: true,
      upload: false,
      status: ["active", "inactive"],
      statuses: [],
    };
  }

  initialRequestParams = () => {
    return {
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  componentDidMount() {
    let scrollY = 0;
    let prevStateValues = popCookie("prevStateValues");
    let usePrevStateValues = popCookie("usePrevStateValues");
    if (usePrevStateValues && prevStateValues) {
      let data = JSON.parse(prevStateValues);
      if (data.currentUrl === this.currentUrl) {
        this.requestParams = data.requestParams;
        scrollY = data.scrollY;
        this.previousLoad = true;
      } else {
        this.requestParams = this.initialRequestParams();
      }
    } else {
      this.requestParams = this.initialRequestParams();
    }
    this.fetchTableData();
    setTimeout(function() {
      window.scrollTo({ top: scrollY });
    }, 1000);
  }

  flushTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  componentWillUnmount = () => {
    this.flushTimer();

    let prevStateValues = JSON.stringify({
      scrollY: window.scrollY,
      requestParams: this.requestParams,
      currentUrl: this.currentUrl,
    });
    setCookie("prevStateValues", prevStateValues);
  };
  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  fetchTableData = () => {
    fetchStaffsData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  initialFilterState = () => {
    let prevStateValues = getCookie("prevStateValues");
    let staffsname = [];
    let phone_nos = [];
    let departments = [];
    let loader = [];
    let statuses = [];

    if (prevStateValues) {
      let data = JSON.parse(prevStateValues);
      staffsname = data.requestParams.name;
      phone_nos = data.requestParams.phone;
      departments = data.requestParams.department;
      statuses = data.requestParams.status;
      loader = data.requestParams.loader;
    }
    return {
      staffsname,
      phone_nos,
      departments,
      loader,
      statuses,
    };
  };

  getAggregation = async () => {
    await aggregation()
      .then(({ data: { name, phone, department } }) => {
        let staffname = name.map((key) => ({
          label: key,
          value: key,
        }));
        let phoneNum = phone.map((key) => ({
          label: key,
          value: key,
        }));
        let dep = department.filter((key) => (key !== null) && (key !== ""));
        let depart = dep.map((key) => ({
          label: key,
          value: key,
        }));
        let status = [
          { label: "Active", value: "active" },
          { label: "InActive", value: "inactive" },
        ];
        this.setState({
          name: staffname,
          department: depart,
          phone: phoneNum,
          status: status,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.setState({ ...this.state, skeleton: true });
    this.debounceSearch();
  };

  handleSubmitSuccess = () => {
    this.onCancel();
    setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  onCancel = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
      upload: false,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.fetchTableData();
  };

  createAction = () => {
    return createAccess("staffs");
  };

  updateAction = () => {
    return updateAccess("staffs");
  };

  uploadAction = () => {
    return uploadAccess("staffs");
  };

  editStaffs = (row) => {
    this.setState({ formOpen: true, rowData: row });
  };

  aboutStaff = (row) => {
    if (row.phone) {
      this.props.history.push({
        pathname: `/staffs/${row.phone}`,
        state: {
          row,
        },
      });
    }
  };
  StaffsFilterChange = (obj) => {
    this.setState({
      phone_nos: obj.phone,
      staffsname: obj.name,
      departments: obj.department,
      statuses: obj.status,
    });
    this.requestParams.phone = obj.phone;
    this.requestParams.name = obj.name;
    this.requestParams.department = obj.department;
    this.requestParams.status = obj.status;
    this.debounceSearch();
  };

  render() {
    const { data, skeleton, formOpen, rowData, upload } = this.state;
    const {
      onChange,
      handleSubmitSuccess,
      onCancel,
      editStaffs,
      aboutStaff,
      StaffsFilterChange,
      getAggregation,
    } = this;

    const appBarProps = {
      search: this.requestParams.search,
      staffsFilter: true,
      onChange,
      StaffsFilterChange,
      getAggregation,
      staffsFilterObj: {
        name: this.state.name,
        phone: this.state.phone,
        departments: this.state.departments,
        department: this.state.department,
        staffsname: this.state.staffsname,
        phone_nos: this.state.phone_nos,
        other_filter: this.state.other_filter,
        page: this.state.page,
        status: this.state.status,
        statuses: this.state.statuses,
      },
    };
    const tableProps = {
      tabColor: "info",
      title: "CBR Staffs",
      subTitle: "Staffs working in CBR",
      columns: columns(editStaffs, aboutStaff),
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      onRowClick: () => {},
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      OnClickUpload: () => {
        this.setState({ upload: true });
      },
      skeleton,
      dataValue: "ADD CBR Staffs",
      createAction: this.createAction(),
      dataValueUpload: "Upload",
      uploadAction: this.uploadAction(),
    };
    const CRUDStaffProps = {
      open: formOpen,
      tabColor: "info",
      titleText: "Staffs",
      data: rowData,
      handleSubmitSuccess,
      onCancel,
    };

    const CRUDStaffsUploadProps = {
      open: upload,
      titleText: "Staffs Data",
      onCancel: onCancel,
      handleSubmitSuccess,
      upload: true,
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        {formOpen && <CRUDStaffs {...CRUDStaffProps} />}
        <CRUDUpload {...CRUDStaffsUploadProps} />
      </React.Fragment>
    );
  }
}
export default Staffs;

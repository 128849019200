import React from "react";
import { Row, Col } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";
import RadioButton from "components/inputs/radioButton";

function Form(props) {
  const { handleChange, handleChangeNric } = props;
  const { name, phone, status, department, fin_number } = props;
  return (
    <React.Fragment>
      <Row style={{ padding: "10px" }}>
        <Col xs={24} sm={12} md={12} style={{ padding: "5px 10px" }}>
          <SimpleTextInput
            labelText="Name"
            name="name"
            value={name}
            onChange={handleChange}
          />
        </Col>
        <Col item xs={24} sm={12} md={12} style={{ padding: "5px 10px" }}>
          <SimpleTextInput
            labelText="Phone"
            name="phone"
            value={phone}
            onChange={handleChange}
          />
        </Col>

        <Col xs={24} sm={12} style={{ padding: "10px" }}>
          <SimpleTextInput
            labelText="NRIC/FIN (Last 4 digits)"
            name="fin_number"
            required
            value={fin_number}
            onChange={(e) => handleChangeNric(e.target.value)}
            inputProps={{
              maxLength: 9,
            }}
          />
        </Col>

        <Col item xs={24} sm={12} md={12} style={{ padding: "10px" }}>
          <SimpleTextInput
            labelText="Department"
            name="department"
            value={department}
            onChange={handleChange}
          />
        </Col>
        <Col item xs={24} sm={12} md={12} style={{ padding: "10px" }}>
          <RadioButton
            labelText="Status"
            value={status}
            data={{ Active: "active", Inactive: "inactive" }}
            onChange={handleChange}
            name="status"
            color={true}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
}
export default Form;

import React, { Component } from "react";
import AppBar from "components/AppBar";
import RowspanTable from "components/paginationTables/withAddEdit";
import { fetchEmployeesData, aggregation } from "api/employees";
import { columns } from "data/employees";
import CRUDResident from "components/form/employees/CRUD";
import CRUDUpload from "components/form/employees/CRUDUpload";
import { Row } from "antd";
import {
  createAccess,
  updateAccess,
  debounce,
  uploadAccess,
  popCookie,
  setCookie,
} from "helpers";
import { handleResponse } from "components/notifications/handleResponse";

class Employees extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      name: [],
      departments: [],
      department: [],
      phone: [],
      phone_nos: [],
      staffsname: [],
      total_entries: 0,
      formOpen: false,
      rowData: undefined,
      skeleton: true,
      upload: false,
    };
  }
  initialRequestParams = () => {
    return {
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  componentDidMount() {
    let scrollY = 0;
    let prevStateValues = popCookie("prevStateValues");
    let usePrevStateValues = popCookie("usePrevStateValues");
    if (usePrevStateValues && prevStateValues) {
      let data = JSON.parse(prevStateValues);
      if (data.currentUrl === this.currentUrl) {
        this.requestParams = data.requestParams;
        scrollY = data.scrollY;
        this.previousLoad = true;
      } else {
        this.requestParams = this.initialRequestParams();
      }
    } else {
      this.requestParams = this.initialRequestParams();
    }
    this.fetchTableData();
    setTimeout(function() {
      window.scrollTo({ top: scrollY });
    }, 1000);
  }

  flushTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.flushTimer();

    let prevStateValues = JSON.stringify({
      scrollY: window.scrollY,
      requestParams: this.requestParams,
      currentUrl: this.currentUrl,
    });
    setCookie("prevStateValues", prevStateValues);
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  fetchTableData = () => {
    fetchEmployeesData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  getAggregation = async () => {
    await aggregation()
      .then(({ data: { name, phone, department } }) => {
        let staffname = name.map((key) => ({
          label: key,
          value: key,
        }));
        let phoneNum =
          phone.map((key) => ({
            label: key,
            value: key,
          }));
        let dep = department.filter((key) => (key !== null) && (key !== ""));
        let depart =
          dep.map((key) => {
            return (
              ({
                label: key,
                value: key,
              })

            )
          }
          );
        this.setState({
          name: staffname,
          department: depart,
          phone: phoneNum,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.setState({ ...this.state, skeleton: true });
    this.debounceSearch();
  };

  handleSubmitSuccess = () => {
    this.onCancel();
    setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  onCancel = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
      upload: false,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  editEmployee = (row) => {
    this.setState({ formOpen: true, rowData: row });
  };

  employeeHistory = (row) => {
    if (row.phone) {
      this.props.history.push({
        pathname: `/employees/${row.phone}`,
        state: { row },
      });
    }
  };

  handleTableChange = (pagination, filters) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.status = filters.status;
    this.fetchTableData();
  };

  createAction = () => {
    return createAccess("employees");
  };

  updateAction = () => {
    return updateAccess("employees");
  };

  uploadAction = () => {
    return uploadAccess("employees");
  };

  StaffsFilterChange = (obj) => {
    this.setState({
      phone_nos: obj.phone,
      staffsname: obj.name,
      departments: obj.department,
    });
    this.requestParams.phone = obj.phone;
    this.requestParams.name = obj.name;
    this.requestParams.department = obj.department;
    this.debounceSearch();
  };

  render() {
    const { data, formOpen, rowData, skeleton, loader, upload } = this.state;
    const {
      onChange,
      onCancel,
      handleSubmitSuccess,
      getAggregation,
      StaffsFilterChange,
    } = this;

    const appBarProps = {
      search: this.requestParams.search,
      staffsFilter: true,
      onChange,
      getAggregation,
      StaffsFilterChange,
      staffsFilterObj: {
        name: this.state.name,
        phone: this.state.phone,
        departments: this.state.departments,
        department: this.state.department,
        staffsname: this.state.staffsname,
        phone_nos: this.state.phone_nos,
      },
    };
    const tableProps = {
      tabColor: "warning",
      title: "Other Staffs",
      subTitle: "Registered staffs from other buildings",
      columns: columns(
        this.editEmployee,
        this.employeeHistory,
        this.updateAction,
        {
          data: data,
          pagination: this.pagination(),
        }
      ),
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      onRowClick: () => {},
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      OnClickUpload: () => {
        this.setState({ upload: true });
      },
      dataValue: "ADD Staff",
      dataValueUpload: "Upload",
      uploadAction: this.uploadAction(),
      skeleton,
      loader,
      createAction: this.createAction(),
    };

    const CRUDEmployeeProps = {
      open: formOpen,
      tabColor: "warning",
      titleText: "Employees",
      data: rowData,
      handleSubmitSuccess,
      onCancel,
      // showDelete: true,
      dateFilter: true,
    };

    const CRUDEmployeesUploadProps = {
      open: upload,
      titleText: "Staffs Data",
      onCancel: onCancel,
      handleSubmitSuccess,
      upload: true,
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <RowspanTable {...tableProps} />
        </Row>
        <CRUDResident {...CRUDEmployeeProps} />
        <CRUDUpload {...CRUDEmployeesUploadProps} />
      </React.Fragment>
    );
  }
}
export default Employees;

import React, { Component } from "react";
import { Button, Modal, Col, Form, Checkbox } from "antd";
import Select from "react-select";

class LNPRFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      data: {
        status: this.props.statusFilters,
        cameras: this.props.cameraFilters,
        vehicle_type: this.props.vehicle_type,
        number_plate: this.props.number_plates,
        other_filter: this.props.other_filter,
        loader: true,
      },
    };
  }

  handleOk = () => {
    this.props.filterChange(this.state.data);
    this.props.filterOnClick();
  };

  handleReset = () => {
    let data = {
      status: [],
      cameras: [],
      vehicle_type: [],
      number_plate: [],
      other_filter: [],
    };
    this.props.filterChange(data);
    this.props.filterOnClick();
  };

  handleSelectChange = (name, value) => {
    let { data } = this.state;
    data[name] = value;
    this.setState({ data });
  };

  handleCancel = () => {
    this.props.filterOnClick();
  };

  render() {
    const options = [
      "Normal",
      "Taxi",
      "Motor Bike",
      "Special Vehicle",
      "Heavy Vehicle",
    ];
    return (
      <React.Fragment>
        {this.state.visible && (
          <Modal
            centered
            title="Filter Values"
            visible={this.state.visible}
            onCancel={this.handleCancel}
            bodyStyle={{ padding: "24px 24px 300px 24px" }}
            maskClosable={false}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={this.handleReset}
                style={{
                  background: "#3b8bf7",
                  color: "white",
                  borderColor: "#3b8bf72",
                }}
              >
                Reset
              </Button>,
              <Button
                key="back"
                onClick={this.handleOk}
                style={{
                  background: "#3b8bf7",
                  color: "white",
                  borderColor: "#3b8bf7",
                }}
              >
                Apply
              </Button>,
            ]}
            width="50%"
            style={{
              width: "50%",
            }}
          >
            {/* <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
              <p
                style={{
                  fontSize: 11,
                  margin: "-12px 0 0.3em",
                  color: "rgba(0, 0, 0, 0.54)",
                  padding: "0 0 5px 0"
                }}
              >
                Number Plate
              </p>
              <Select
                isMulti
                value={this.state.data.number_plate}
                options={this.props.number_plate}
                onChange={value => {
                  this.handleSelectChange("number_plate", value);
                }}
                closeMenuOnSelect={false}
                isClearable={true}
                style={{
                  marginTop: "-6px",
                  marginBottom: "-6px",
                  padding: "3px",
                  minHeight: "1px",
                  maxHeight: "15px"
                }}
              />
            </Col> */}
            <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
              <p
                style={{
                  fontSize: 12,
                  margin: "-12px 0 0.3em",
                  color: "rgba(0, 0, 0, 0.54)",
                  padding: "0 0 5px 0",
                }}
              >
                Status
              </p>
              <Select
                isMulti
                value={this.state.data.status}
                options={this.props.statusFilter}
                onChange={(value) => {
                  this.handleSelectChange("status", value);
                }}
                closeMenuOnSelect={false}
                isClearable={false}
                style={{
                  marginTop: "-6px",
                  marginBottom: "-6px",
                  padding: "3px",
                  minHeight: "1px",
                  height: "40px",
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
              <p
                style={{
                  fontSize: 12,
                  margin: "0.9em 0 -0.5em",
                  color: "rgba(0, 0, 0, 0.54)",
                  padding: "1px 0 15px 0",
                }}
              >
                Camera Location
              </p>
              <Select
                isMulti
                value={this.state.data.cameras}
                options={this.props.cameraFilter}
                onChange={(value) => this.handleSelectChange("cameras", value)}
                closeMenuOnSelect={false}
                isClearable={false}
              />
            </Col>

            <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
              <Form.Item>
                <p
                  style={{
                    fontSize: 12,
                    margin: "0.1em 0 -0.7em",
                    color: "rgba(0, 0, 0, 0.54)",
                    padding: "5px 0 0 0",
                  }}
                >
                  Vehicle Type
                </p>
                <Checkbox.Group
                  options={options}
                  value={this.state.data.vehicle_type}
                  name="vehicle_type"
                  onChange={(value) => {
                    this.handleSelectChange("vehicle_type", value);
                  }}
                />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
              <Form.Item>
                <p
                  style={{
                    fontSize: 12,
                    margin: "-2.1em 0 -0.7em",
                    color: "rgba(0, 0, 0, 0.54)"
                  }}
                >
                  Other Filter
                </p>
                <Checkbox.Group
                  style={{ paddingRight: "25px" }}
                  options={filterOptions}
                  value={this.state.data.other_filter}
                  onChange={value => {
                    this.handleSelectChange("other_filter", value);
                  }}
                />
              </Form.Item>
            </Col> */}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default LNPRFilter;

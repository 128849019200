import makeRequest from ".";

function generateParams(params = {}) {
  let Empname = "";
  let phone_no = "";
  let dep = "";
  const { name, phone, department } = params;
  if (name) {
    Empname = name.map((value) => `&name[]=${value.label}`).join("");
  }
  if (phone && phone.length > 0) {
    phone_no = phone.map((key) => `&phone[]=${key.label}`).join("");
  }

  if (department && department.length > 0) {
    dep = department.map((key) => `&department[]=${key.label}`).join("");
  }

  return `${Empname}${phone_no}${dep}&query=${
    params.search
  }&page=${params.page || 1}&per_page=${params.perPage}`;
}

export function fetchEmployeesData(requestParams) {
  return makeRequest({
    uri: `/api/v1/employees?${generateParams(requestParams)}`,
  });
}

export function createEmployee(requestBody) {
  return makeRequest({
    uri: `/api/v1/employees`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function aggregation() {
  return makeRequest({
    uri: `/api/v1/employees/aggs`,
    method: "GET",
  });
}

export function updateEmployee(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/employees/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function uploadBulkEmployees(body) {
  return makeRequest({
    uri: `/api/v1/employees/import`,
    method: "POST",
    body,
  });
}

import makeRequest from ".";

function generateParams(params = {}) {
  let Staffsname = "";
  let phone_no = "";
  let dep = "";
  let statuses = "";

  const { name, phone, department, status } = params;

  if (status) {
    statuses = status.map((key) => `&status[]=${key.value}`).join("");
  }

  if (name) {
    Staffsname = name.map((value) => `&name[]=${value.label}`).join("");
  }
  if (phone && phone.length > 0) {
    phone_no = phone.map((key) => `&phone[]=${key.label}`).join("");
  }

  if (department && department.length > 0) {
    dep = department.map((key) => `&department[]=${key.label}`).join("");
  }

  return `${Staffsname}${phone_no}${statuses}${dep}&query=${
    params.search
  }&page=${params.page || 1}&per_page=${params.perPage}`;
}

export function fetchStaffsData(requestParams) {
  return makeRequest({
    uri: `/api/v1/staffs?${generateParams(requestParams)}`,
  });
}

export function createStaffs(requestBody) {
  return makeRequest({
    uri: `/api/v1/staffs`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateStaffs(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/staffs/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

function StaffEntryParams(params = {}, totalEntries = null) {
  return `from_time=${params.fromTime || ""}&to_time=${params.toTime ||
    ""}&query=${params.search || ""}&page=${params.page ||
    1}&per_page=${totalEntries || params.perPage}`;
}

export function fetchStaffEntry(requestParams) {
  return makeRequest({
    uri: `/api/v1/staff_entries?${StaffEntryParams(requestParams)}`,
  });
}

export function getStaffEntries(requestParams, id) {
  return makeRequest({
    uri: `/api/v1/staff_entries?staff_id=${id}&from_time=${
      requestParams.fromTime
    }&to_time=${requestParams.toTime}&page=${requestParams.page ||
      1}&per_page=${requestParams.perPage}
    `,
  });
}

export function getStaffData(id) {
  return makeRequest({
    uri: `/api/v1/staffs/${id}/about`,
  });
}

export function aggregation() {
  return makeRequest({
    uri: `/api/v1/staffs/aggs`,
    method: "GET",
  });
}

export function regenerateId(id) {
  return makeRequest({
    uri: `/api/v1/staffs/${id}/revoke_identity_id`,
    method: "PUT",
  });
}

export function uploadBulkStaff(body) {
  return makeRequest({
    uri: `/api/v1/staffs/import`,
    method: "POST",
    body,
  });
}

import React, { Component } from "react";
import { Button, Modal, Col } from "antd";
import Select from "react-select";

class StaffsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      data: {
        name: this.props.staffsname,
        phone: this.props.phone_nos,
        department: this.props.departments,
        employer: this.props.employers,
        status: this.props.statuses,
      },
    };
  }

  handleOk = () => {
    this.props.StaffsFilterChange(this.state.data);
    this.props.staffFilterOnClick();
  };

  handleReset = () => {
    let data = {
      phone: [],
      name: [],
      department: [],
      employer: [],
      status: [],
    };
    this.props.StaffsFilterChange(data);
    this.props.staffFilterOnClick();
  };

  handleSelectChange = (name, value) => {
    let { data } = this.state;
    data[name] = value;
    this.setState({
      data,
      loading: false,
    });
  };

  handleCancel = () => {
    this.props.staffFilterOnClick();
  };

  render() {
    const { type } = this.props;
    return (
      <React.Fragment>
        {this.state.visible && (
          <Modal
            centered
            title="Filter Values"
            visible={this.state.visible}
            onCancel={this.handleCancel}
            bodyStyle={{ padding: "24px 24px 335px 24px" }}
            maskClosable={false}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={this.handleReset}
                style={{
                  background: "#3b8bf7",
                  color: "white",
                  borderColor: "#3b8bf72",
                }}
              >
                Reset
              </Button>,
              <Button
                key="back"
                onClick={this.handleOk}
                style={{
                  background: "#3b8bf7",
                  color: "white",
                  borderColor: "#3b8bf7",
                }}
              >
                Apply
              </Button>,
            ]}
            width="50%"
            style={{
              width: "50%",
            }}
          >
            <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
              <p
                style={{
                  fontSize: 11,
                  margin: "-12px 0 0.3em",
                  color: "rgba(0, 0, 0, 0.54)",
                }}
              >
                Name
              </p>
              <Select
                isMulti
                value={this.state.data.name}
                options={this.props.name}
                onChange={(value) => {
                  this.handleSelectChange("name", value);
                }}
                closeMenuOnSelect={false}
                isClearable={false}
              />
            </Col>
            <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
              <div
                style={{
                  margin: "-12px 0 -12px 0",
                  padding: "25px 0 25px",
                }}
              >
                <p
                  style={{
                    fontSize: 11,
                    margin: "0.1em 0 -0.7em",
                    color: "rgba(0, 0, 0, 0.54)",
                    padding: "1px 0 15px 0",
                  }}
                >
                  Phone Number
                </p>
                <Select
                  style={{ margin: "-6px 0px -6px 0px" }}
                  isMulti
                  value={this.state.data.phone}
                  options={this.props.phone}
                  onChange={(value) => {
                    this.handleSelectChange("phone", value);
                  }}
                  closeMenuOnSelect={false}
                  isClearable={false}
                />
              </div>
            </Col>
            {type ? (
              <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
                <div
                  style={{
                    margin: "-12px 0 -12px 0",
                    padding: "25px 0 25px",
                  }}
                >
                  <p
                    style={{
                      fontSize: 11,
                      margin: "0.1em 0 -0.7em",
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "1px 0 15px 0",
                    }}
                  >
                    Employer
                  </p>
                  <Select
                    style={{ margin: "-6px 0px -6px 0px" }}
                    isMulti
                    value={this.state.data.employer}
                    options={this.props.employer}
                    onChange={(value) => {
                      this.handleSelectChange("employer", value);
                    }}
                    closeMenuOnSelect={false}
                    isClearable={false}
                  />
                </div>
              </Col>
            ) : (
              <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
                <div
                  style={{
                    margin: "-12px 0 -12px 0",
                    padding: "25px 0 25px",
                  }}
                >
                  <p
                    style={{
                      fontSize: 11,
                      margin: "0.1em 0 -0.7em",
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "1px 0 15px 0",
                    }}
                  >
                    Group
                  </p>
                  <Select
                    style={{ margin: "-6px 0px -6px 0px" }}
                    isMulti
                    value={this.state.data.department}
                    options={this.props.department}
                    onChange={(value) => {
                      this.handleSelectChange("department", value);
                    }}
                    closeMenuOnSelect={false}
                    isClearable={false}
                  />
                </div>
              </Col>
            )}
            <Col xs={12} sm={12} md={12} style={{ padding: "0px 10px" }}>
              <div
                style={{
                  margin: "-12px 0 -12px 0",
                  padding: "25px 0 25px",
                }}
              >
                <p
                  style={{
                    fontSize: 11,
                    margin: "0.1em 0 -0.7em",
                    color: "rgba(0, 0, 0, 0.54)",
                    padding: "1px 0 15px 0",
                  }}
                >
                  Status
                </p>
                <Select
                  style={{ margin: "-6px 0px -6px 0px" }}
                  isMulti
                  value={this.state.data.status}
                  options={this.props.status}
                  onChange={(value) => {
                    this.handleSelectChange("status", value);
                  }}
                  closeMenuOnSelect={false}
                  isClearable={false}
                />
              </div>
            </Col>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default StaffsFilter;

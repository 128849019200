import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TiWaves } from "react-icons/ti";
import {
  MdOpacity,
  MdUpdate,
  MdAddBox,
  MdArrowUpward,
  MdArrowDownward,
} from "react-icons/md";
import { apiHost } from "config";
import { headers } from "helpers";
import { compareTwoArray } from "helpers";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import {
  Card,
  CardIcon,
  CardHeader,
  CardFooter,
} from "material-dashboard-react/components";
import { TableContext } from "context/ContextProvider";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { handleResponse } from "components/notifications/handleResponse";

class Cards extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.meter_id = undefined;
    this.meterSelected = [];
    this.state = {
      absolute: 0,
      created_at: null,
      consumption: {
        color: "#999",
        icon: "",
        text: "",
        last_24_hours_consumption: 0,
        avgValue: 0,
      },
    };
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    this.fetchAbsoluteMeterReading(this.meter_id, true);
    this.fetchConsumption(this.meterSelected, true);
    this.timer = setTimeout(this.autoRefresh, 1000 * 60);
  };

  componentDidMount = () => {
    this.autoRefresh();
  };

  fetchConsumption = (meterSelected, refresh = false) => {
    if (!meterSelected.length === 0) {
      return;
    }
    if (compareTwoArray(meterSelected, this.meterSelected) && !refresh) {
      return;
    }
    let ids = meterSelected
      .map((id) => {
        return `&ids[]=${id}`;
      })
      .join("");

    fetch(`${apiHost}/api/v1/water_meters/avg_data?${ids}`, {
      method: "GET",
      headers: headers(),
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        const errorMessage = [response.status, await response.json()];
        throw errorMessage;
      })
      .then(({ data }) => {
        let last_24_hours_consumption = 0;
        let cummulative_avg = 0;
        meterSelected.forEach((id) => {
          last_24_hours_consumption += data[id].last_24_hours;
          cummulative_avg += data[id].cummulative_avg;
        });
        this.setState({ consumption: { last_24_hours_consumption } });
        let avg = (last_24_hours_consumption / cummulative_avg) * 100 - 100;
        if (avg < 0) {
          this.setState((prevState) => ({
            consumption: {
              ...prevState.consumption,
              color: "green",
              text: `${Math.abs(avg).toFixed(2)}% Lower than usual`,
              icon: <MdArrowDownward />,
            },
          }));
        } else {
          this.setState((prevState) => ({
            consumption: {
              ...prevState.consumption,
              color: "red",
              text: `${Math.abs(avg).toFixed(2)}% Higher than usual`,
              icon: <MdArrowUpward />,
            },
          }));
        }
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  fetchAbsoluteMeterReading = (id, refresh = false) => {
    if (!id || (id === this.meter_id && !refresh)) {
      return;
    }
    fetch(`${apiHost}/api/v1/water_meters/${id}/latest_reading`, {
      method: "GET",
      headers: headers(),
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        const errorMessage = [response.status, await response.json()];
        throw errorMessage;
      })
      .then(({ data: { absolute, created_at } }) => {
        this.setState({ absolute, created_at });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  totalConsumption = (data, meters, meterSelected) => {
    if (!data || data.length === 0) {
      return "";
    }
    let total = 0;
    data.forEach((e) =>
      meters[meterSelected].forEach((id) => (total += e[id]))
    );
    return total;
  };

  render() {
    const { classes } = this.props;
    return (
      <TableContext.Consumer>
        {({ state: { meters, meterSelected, data } }) => {
          if (meterSelected !== "All Meters") {
            this.fetchAbsoluteMeterReading(meters[meterSelected][0]);
            this.meter_id = meters[meterSelected][0];
          }
          this.fetchConsumption(meters[meterSelected]);
          this.meterSelected = meters[meterSelected];
          return (
            <Grid container style={{ margin: "0 -8px", width: "unset" }}>
              <Grid item xs={12} sm={6} md={4} style={{ padding: "0 8px" }}>
                <Card style={{ marginBottom: 0 }}>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <TiWaves style={{ fontSize: "25px" }} />
                    </CardIcon>
                    <p className={classes.cardCategory}>Last 24 Hrs</p>
                    <h2 className={classes.cardTitle}>
                      {this.state.consumption.last_24_hours_consumption} Units
                    </h2>
                  </CardHeader>
                  <CardFooter stats>
                    <div
                      className={classes.stats}
                      style={{ color: this.state.consumption.color }}
                    >
                      {this.state.consumption.icon}
                      {this.state.consumption.text}
                    </div>
                  </CardFooter>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ padding: "0 8px" }}>
                <Card style={{ marginBottom: 0 }}>
                  <CardHeader color="primary" stats icon>
                    <CardIcon color="primary">
                      <MdOpacity />
                    </CardIcon>
                    <p className={classes.cardCategory}>Current Reading</p>
                    <h2 className={classes.cardTitle}>
                      {meterSelected !== "All Meters"
                        ? this.state.absolute
                        : "N/A"}
                    </h2>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <MdUpdate />
                      Updated
                      {meterSelected !== "All Meters"
                        ? this.state.created_at &&
                          moment
                            .duration(
                              moment(this.state.created_at).diff(moment())
                            )
                            .humanize(true)
                        : "N/A"}
                    </div>
                  </CardFooter>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ padding: "0 8px" }}>
                <Card style={{ marginBottom: 0 }}>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <TiWaves style={{ fontSize: "25px" }} />
                    </CardIcon>
                    <p className={classes.cardCategory}>{meterSelected}</p>
                    <h2 className={classes.cardTitle}>
                      {this.totalConsumption(data, meters, meterSelected)} Units
                    </h2>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <MdAddBox />
                      Total Consumptions
                    </div>
                  </CardFooter>
                </Card>
              </Grid>
            </Grid>
          );
        }}
      </TableContext.Consumer>
    );
  }
}

export default withStyles(dashboardStyle)(Cards);

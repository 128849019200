import React, { Component } from "react";
import { columns } from "data/users";
import AppBar from "components/AppBar";
import { fetchListingData } from "api/users";
import PaginationTable from "components/paginationTables/withAddEdit";
import CRUDVehicle from "components/form/users/CRUD";
import { Row } from "antd";
import { handleResponse } from "components/notifications/handleResponse";
import { createAccess, updateAccess, debounce } from "helpers";

class Users extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      rowData: undefined,
      formOpen: false,
      skeleton: true,
      loader: false,
    };
  }

  initialRequestParams = () => {
    return {
      perPage: 25,
      page: 1,
      search: "",
      order: "first_name",
      type: ["User"],
    };
  };

  componentDidMount = () => {
    this.fetchTableData();
  };

  fetchTableData = () => {
    fetchListingData(this.requestParams)
      .then(({ data, total_entries }) => {
        let roles_value = localStorage.getItem("roles");

        if (roles_value?.includes("Super Admin")) {
          this.setState({
            data: data,
            total_entries: total_entries,
            skeleton: false,
            loader: false,
          });
        } else {
          let user_data = [];
          data.map((user) => {
            if (
              !user?.email?.includes("katomaran") &&
              !user?.email?.includes("dotworld")
            ) {
              return user_data.push(user);
            } else {
              return null;
            }
          });
          this.setState({
            data: user_data,
            skeleton: false,
            loader: false,
          });
        }
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    this.timer = setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;

    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    let field = sorter && sorter.field;
    let order = sorter && sorter.order === "ascend" ? "asc" : "desc";
    this.requestParams.order = field && `${field} ${order}`;
    this.requestParams.type = filters["roles"];
    this.fetchTableData();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.setState(this.state);
    this.debounceSearch();
  };

  createAction = () => {
    return createAccess("users");
  };

  updateAction = () => {
    return updateAccess("users");
  };

  onRowClick = (row) => {
    let access = this.updateAction();
    if (access) {
      this.setState({ formOpen: true, rowData: row });
    }
  };

  render() {
    const { data, rowData, formOpen, skeleton, loader } = this.state;

    const { handleClose, handleSubmitSuccess, onChange, onRowClick } = this;
    const CRUDProps = {
      data: rowData,
      onCancel: handleClose,
      handleSubmitSuccess,
      open: formOpen,
      tabColor: "warning",
      logOutUser: true,
    };
    const tableProps = {
      tabColor: "warning",
      title: "Users",
      subTitle: "Users here",
      columns: columns,
      data: data,
      skeleton,
      loader,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      onRowClick,
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      dataValue: "ADD Users",
      createAction: this.createAction(),
    };

    const appBarProps = {
      search: this.requestParams.search,
      searchOpen: true,
      onChange,
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        <CRUDVehicle {...CRUDProps} />
      </React.Fragment>
    );
  }
}

export default Users;

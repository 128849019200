import React, { Component } from "react";
import Form from "./Form";
import { createStaffs, updateStaffs } from "api/staffs";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = null;
    this.state = {
      open: false,
      name: "",
      phone: "",
      department: "",
      status: "active",
      nricPrier: "XXXXX",
      fin_number: "",
    };
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeNric = (value) => {
    let { nricPrier, fin_number } = this.state;
    let fin = fin_number
      ? value.slice(0, 9)
      : `${nricPrier}${value.slice(0, 4)}`;
    this.setState({ fin_number: fin });
  };

  requestParams = () => {
    const { name, phone, status, department, fin_number } = this.state;

    return {
      staffs: {
        name,
        phone,
        status,
        department,
        fin_number,
      },
    };
  };

  onSubmit = () => {
    const { id } = this;
    const method = !id ? createStaffs : updateStaffs;
    method(this.requestParams(), id)
      .then((data) => {
        success(data.message);
        this.props.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    const { data } = this.props;

    if (data) {
      this.id = data.id;
      this.setState({
        name: data?.name,
        phone: data?.phone,
        status: data?.status,
        department: data?.department,
        fin_number: data?.fin_number,
      });
    }
  };
  render() {
    const { handleChange, id } = this;
    const { name, phone, status, department, fin_number } = this.state;

    const props = {
      name,
      phone,
      fin_number,
      status,
      department,
      id,
    };

    return (
      <Form
        handleChange={handleChange}
        handleChangeNric={this.handleChangeNric}
        {...props}
      />
    );
  }
}

export default DialogForm("CBR Staffs")(CRUD);

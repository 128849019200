import { presence } from "helpers";
export const columnBlock = [
  {
    title: "Block",
    dataIndex: "block",
    align: "center",
    render: (block) => `${presence(block)}`,
  },
  {
    title: "No of Floors",
    dataIndex: "floors",
    align: "center",
    render: (floors) => floors && floors.length,
  },
  {
    title: "No of Units",
    dataIndex: "floors",
    align: "center",
    render: (floors) =>
      floors &&
      floors
        .map((floor) => {
          return floor.units.length;
        })
        .flat()
        .reduce((a, b) => a + b, 0),
  },
];

export const columnFloor = [
  {
    title: "Floor",
    align: "center",
    dataIndex: "floor",
    render: (floor) => `${presence(floor)}`,
  },
  {
    title: "No of Units",
    dataIndex: "units",
    align: "center",
    render: (units) => units.length,
  },
];

export const columnUnit = () => {
  return [
    {
      title: "Unit Number",
      dataIndex: "unit",
      align: "center",
    },
  ];
};

import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { siteName } from "config";
import themeJSON from "./ui/theme/theme";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import CustomRoutes from "routes/CustomRoutes";
import MyProvider from "./context/MyProvider";
import "App.css";
import "antd/dist/antd.css";

let theme = createMuiTheme(themeJSON);
theme = responsiveFontSizes(theme);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    // initFcm();
    // messaging.onMessage(payload => {
    //   console.log(payload);
    // });

    // navigator.serviceWorker.addEventListener("message", ({ data }) => {
    //   console.log(data);
    // });

    document.getElementById(
      "title"
    ).text = `${siteName} - Building Management System`;
  };

  render() {
    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <MyProvider>
            <CustomRoutes />
          </MyProvider>
        </ThemeProvider>
      </BrowserRouter>
    );
  }
}
export default App;

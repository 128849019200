import makeRequest from ".";

export function fetchBlockFloorUnit(announcement) {
  if (announcement) {
    return makeRequest({
      uri: `/api/v1/units/list?announcement=${announcement["announcement"]}`,
    });
  } else {
    return makeRequest({
      uri: `/api/v1/units/list`,
    });
  }
}

export function fetchResidentDetails(block, floor) {
  return makeRequest({
    uri: `/api/v1/units/residents?&block=${block}&floor=${floor}`,
  });
}

export function uploadBulkUnit(body) {
  return makeRequest({
    uri: `/api/v1/units/bulk_upload`,
    method: "POST",
    body,
  });
}

export function aboutUnitData(unit) {
  return makeRequest({
    uri: `/api/v1/units/${unit}/about`,
  });
}

export function makePrimary(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/units/${id}/primary_resident`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function assingVehicleNumber(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/units/${id}/vehicle_number`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

// export function searchUnitData({ perPage, search }) {
//   let value = search && search.split('#');
//   return makeRequest({
//     uri: `/api/v1/units/search_units?query=${
//       value[1] ? `${value[0]}-${value[1]}` : value[0] ? value[0] : ''
//     }`,
//   });
// }

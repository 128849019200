import React, { Component } from "react";
import AppBar from "components/AppBar";
import UnitTable from "components/paginationTables/units";
import { fetchBlockFloorUnit } from "api/unit";
import { Row } from "antd";
import { handleResponse } from "components/notifications/handleResponse";
import CRUDUnit from "components/form/unit/CRUD";
import { BsUpload } from "react-icons/bs";
import { uploadAccess, debounce } from "helpers";

class Units extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      residentData: [],
      total_entries: 0,
      formOpen: false,
      rowData: undefined,
    };
  }
  initialRequestParams = () => {
    return {
      search: "",
      perPage: 25,
      page: 1,
    };
  };
  componentDidMount() {
    this.fetchTableData();
  }

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  fetchTableData = () => {
    fetchBlockFloorUnit()
      .then(({ blocks }) => {
        this.setState({
          data: blocks,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };
  onCancel = () => {
    this.setState({ formOpen: false });
  };

  handleSubmitSuccess = () => {
    this.onCancel();
    setTimeout(() => {
      this.fetchTableData();
    }, 5000);
  };

  aboutUnit = (row) => {
    let number = row.unit
      ? `${row.block}-${row.floor}-${row.unit}`
      : `${row.block}-${row.floor}`;
    this.props.history.push(`/units/${number}`);
  };

  uploadAction = () => {
    return uploadAccess("units");
  };

  render() {
    const { data, formOpen } = this.state;
    const { onCancel, handleSubmitSuccess, aboutUnit } = this;

    const tableProps = {
      tabColor: "info",
      normalIcon: <BsUpload />,
      title: "Units",
      subTitle: "Block Floor Units Of The Apartment",
      data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      dataValue: "Upload",
      aboutUnit: aboutUnit,
      createAction: this.uploadAction(),
    };
    const CRUDUnitProps = {
      open: formOpen,
      titleText: "Units Data",
      onCancel,
      handleSubmitSuccess,
      upload: true,
    };

    return (
      <React.Fragment>
        <AppBar />
        <Row>
          <UnitTable {...tableProps} />
        </Row>
        <CRUDUnit {...CRUDUnitProps} />
      </React.Fragment>
    );
  }
}
export default Units;

import React from "react";
import { presence } from "helpers";
// import { apiHost } from "config";

export const columns = [
  // {
  //   title: "Image",
  //   align: "center",
  //   dataIndex: "image_path",
  //   render: (image_path) => {
  //     return (
  //       <>
  //         <div>
  //           {image_path?.startsWith("https") ? (
  //             <img src={image_path} alt="staff" className="profile-pic" />
  //           ) : (
  //             <img
  //               src={`${apiHost}/${image_path}`}
  //               alt="staffs"
  //               className="profile-pic"
  //             />
  //           )}
  //         </div>
  //       </>
  //     );
  //   },
  // },
  {
    title: "Name",
    align: "center",
    render: (_, row) => {
      return <>{presence(row.staff?.name)}</>;
    },
  },
  {
    title: "Phone",
    align: "center",
    render: (_, row) => {
      return <>{presence(row.staff?.phone)}</>;
    },
  },
  {
    title: "Group",
    align: "center",
    render: (_, row) => {
      return <>{presence(row.staff?.department)}</>;
    },
  },
  {
    title: "Temperature",
    align: "center",
    render: (_, row) => {
      return <>{presence(row.temperature)}</>;
    },
  },
  {
    title: "Device Name",
    align: "center",
    render: (_, row) => {
      return <>{presence(row.device_name)}</>;
    },
  },
];

import React from "react";
import { presence, formatTime, titleize } from "helpers";
import { Button } from "antd";
// import { RolesTiles } from "./users";

export const columns = (aboutAnnouncement) => [
  {
    title: "Created By",
    align: "center",
    render: (row) => {
      if (row.created_at)
        return (
          <React.Fragment>
            <p style={{ margin: 0 }}>{presence(row.sender)}</p>
            <p style={{ margin: 0, fontWeight: 600 }}>
              {presence(formatTime(row.created_at))}
            </p>
          </React.Fragment>
        );
      else
        return (
          <p style={{ margin: 0 }}>{presence(row.sender || row.sent_at)}</p>
        );
    },
  },
  {
    title: "Message",
    dataIndex: "message",
    align: "center",
  },
  {
    title: "Location",
    dataIndex: "location",
    align: "center",
    render: (location) => {
      let result = location.split("-");
      if (result[1]) {
        return (
          <React.Fragment>
            <p style={{ margin: 0, fontWeight: 600 }}>
              Block - {result[0]} <br />
              Floor - {result[1]}
            </p>
          </React.Fragment>
        );
      } else if (result.length === 1 && result[0] !== "all_block") {
        return (
          <React.Fragment>
            <p style={{ margin: 0, fontWeight: 600 }}>
              Block - {result[0]} <br />
              All Floors
            </p>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <p style={{ margin: 0, fontWeight: 600 }}>{titleize(location)}</p>
          </React.Fragment>
        );
      }
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    // render: status => <RolesTiles role_group={status && titleize(status)} />
    // render: status => <RolesTiles role_group={status && titleize(status)} />
  },
  {
    title: "Sent to",
    align: "center",
    dataIndex: "sent_count",
    render: (sent_count) => {
      if (sent_count)
        return (
          <React.Fragment>
            <p style={{ margin: 0, fontWeight: 600 }}>
              {sent_count.outbox_count}
            </p>
            <p style={{ margin: 0, color: "red" }}>
              {sent_count.resident_count - sent_count.outbox_count}
            </p>
          </React.Fragment>
        );
      else return <p>{presence(sent_count.outbox_count)}</p>;
    },
  },
  {
    title: "Manage",
    render: (row) => {
      return (
        <React.Fragment>
          <Button
            onClick={() => aboutAnnouncement(row)}
            style={{
              margin: "0px 10px 5px 10px",
            }}
            type="primary"
          >
            Details
          </Button>
        </React.Fragment>
      );
    },
    align: "center",
  },
];

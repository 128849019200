import makeRequest from ".";

function generateParams(params = {}) {
  return `query=${params.search || ""}&page=${params.page ||
    1}&per_page=${params.perPage || 5}`;
}

export function fetchAnnouncements(requestParams) {
  return makeRequest({
    uri: `/api/v1/announcements?${generateParams(requestParams)}`,
  });
}

export function createAnnouncements(requestBody) {
  return makeRequest({
    uri: `/api/v1/announcements`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateAnnouncements(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/announcements/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function showAnnouncements(id) {
  return makeRequest({
    uri: `/api/v1/announcements/${id}`,
  });
}

import React, { Component } from "react";
import moment from "moment/moment";
import { presence } from "helpers";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { MdPersonPin } from "react-icons/md";
import {
  Card,
  CardIcon,
  CardHeader,
} from "material-dashboard-react/components";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { fetchOutsiderPhone } from "api/outsiders";
import { Row, Skeleton } from "antd";
import { handleResponse } from "components/notifications/handleResponse";
import RecentEntryGraph from "../../views/Home/Sections/recentEntryGraph.jsx";

const VisitorDetails = ({ visitorDetails }) => {
  const {
    phone,
    name,
    latest_entry_at,
    no_of_visits,
    color,
    icon,
  } = visitorDetails;
  return (
    phone && (
      <Card style={{ flex: 1, marginRight: 20, paddingBottom: 20 }}>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>{icon}</CardIcon>
          <table className="vehicle-details">
            <tr>
              <td className="dash">Visitor Name</td>
              <td>{presence(name)}</td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>{presence(phone)}</td>
            </tr>
            <tr>
              <td>Latest Entry</td>
              <td>
                {moment(presence(latest_entry_at)).format(
                  "MMM Do YYYY, h:mm a"
                )}
              </td>
            </tr>
            <tr>
              <td>Number of Visits</td>
              <td>{presence(no_of_visits)}</td>
            </tr>
          </table>
        </CardHeader>
      </Card>
    )
  );
};

class AboutVisitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      skeleten: true,
      visitors: [],
      visitorTiles: {},
    };
  }

  componentDidMount = () => {
    this.fetchCallOutsider();
  };

  fetchCallOutsider = () => {
    fetchOutsiderPhone(this.props.phone)
      .then(({ data: { name, phone, latest_entry_at, no_of_visits } }) => {
        this.setState({
          visitorTiles: {
            name,
            phone,
            latest_entry_at,
            no_of_visits,
          },
          skeleten: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const { visitorTiles, skeleten, visitors } = this.state;
    const { phone } = this.props;

    const visitorInfo = {
      ...visitorTiles,
      color: "primary",
      icon: <MdPersonPin />,
    };

    if (skeleten) {
      return (
        <React.Fragment>
          <Row>
            <Skeleton
              style={{ width: "100%", padding: "10px 50px" }}
              active={true}
              title={false}
              paragraph={{
                rows: 5,
              }}
            />
          </Row>
        </React.Fragment>
      );
    }

    if (phone) {
      return (
        <React.Fragment>
          <Row style={{ display: "flex" }}>
            <VisitorDetails visitorDetails={visitorInfo} />
            <RecentEntryGraph
              alertTypes={visitors}
              graphHeight="250"
              phone={phone}
            />
          </Row>
        </React.Fragment>
      );
    }

    return <></>;
  }
}

AboutVisitor.propTypes = {
  phone: PropTypes.string.isRequired,
};

export default withStyles(dashboardStyle)(AboutVisitor);

import React, { Component } from "react";
import Form from "./Form";
import { updateKiosk, createKiosk } from "api/kiosk";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";
import { titleize } from "helpers";
import { fetchSoftwares } from "api/software";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.timer = null;
    this.state = {
      data: {
        name: "",
        mac_address: "",
        device_type: this.props.data?.device_type || "vms",
        status: "active",
        bots_data: [],
        unassignedBot: [],
        selected_bots: "",
        timeout: "180",
        software: "",
        software_id: "",
        description: "",
      },
      listSoft: "",
      open: false,
      softwareData: [],
    };
  }

  componentDidMount = () => {
    this.fetchSoftwaresData(this.state.data.device_type);
    if (this.props.data) {
      const {
        id,
        name,
        device_type,
        mac_address,
        type,
        status,
        timeout,
        description,
      } = this.props.data;
      this.id = id;
      this.setState({
        data: {
          ...this.state.data,
          device_type,
          name,
          mac_address,
          type,
          status,
          timeout,
          description,
        },
        id,
      });
    }
  };

  handleChange = (e) => {
    const { data } = this.state;
    data[e.target.id] = e.target.value;
    this.setState({ data });
  };

  handleChangeSelect = (e) => {
    const { data } = this.state;
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };

  handleDeviceChange = (e) => {
    const { data } = this.state;
    data[e.target.name] = e.target.value;
    data["software_id"] = "";
    this.setState({ data }, () => {
      this.fetchSoftwaresData(e.target.value);
    });
  };

  fetchSoftwaresData = async (value) => {
    await fetchSoftwares({ category: [value] })
      .then(({ data }) => {
        let selected_soft = "";
        const software_id = this.props.data?.software_id;
        if (software_id) {
          data.some((software) => {
            if (software.id === software_id) {
              selected_soft = {
                label: titleize(
                  `${software.name}-(${software.version})-(${software.status})`
                ),
                value: software.id,
              };
            }
            return true;
          });
        }
        this.setState({
          softwareData: data.map((s) => ({
            label: titleize(`${s.name}-(${s.version})-(${s.status})`),
            value: s.id,
          })),
          data: {
            ...this.state.data,
            software_id: selected_soft,
          },
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  tabChange = (e) => {
    this.setState({
      device_type: e.target.value,
      name: "",
      mac_address: "",
      status: "",
      selected_bots: "",
      timeout: "",
      software_id: "",
      description: "",
    });
    this.setState({ [e.target.name]: e.target.value });
  };

  requestParams = () => {
    const {
      data: { selected_bots, software_id, ...rest },
    } = this.state;
    const { id } = this;

    return {
      kiosks: {
        id,
        ...rest,
        bot_id: selected_bots?.value,
        software_id: software_id?.value || "",
      },
    };
  };

  onSubmit = () => {
    const { id, requestParams, props } = this;
    let requestValue = requestParams();
    const action = id ? updateKiosk : createKiosk;
    action(requestValue, id)
      .then((data) => {
        success(data.message);
        props.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const { device } = this.props;
    const {
      id,
      handleChange,
      handleChangeSelect,
      tabChange,
      handleDeviceChange,
    } = this;
    const { data, listSoft, softwareData } = this.state;
    const props = {
      id,
      data,
      device,
      handleDeviceChange,
      handleChange,
      handleChangeSelect,
      tabChange,
      listSoft,
      softwareData,
    };
    return (
      <>
        <Form {...props} />
      </>
    );
  }
}
export default DialogForm("Kiosk", 620)(CRUD);

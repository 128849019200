import React from "react";
import { Tooltip, Tag, Button } from "antd";
import { BotIcon } from "data/camera";
import { titleize, presence, timeDiff, formatTime } from "helpers";
import moment from "moment";

export const kioskColumns = (
  editDevice,
  aboutDevice,
  workflowDevice,
  update_permission,
  show_permission
) => {
  return [
    {
      title: "",
      align: "center",
      // width: "7%",
      render: (row) => BotIcon(row.bot_id),
    },
    {
      title: "Name / MAC Address",
      align: "center",
      render: (row) => {
        if (row.name && row.mac_address)
          return (
            <React.Fragment>
              <p style={{ margin: 0 }}>{presence(titleize(row.name))}</p>
              <p style={{ margin: 0, fontWeight: 600 }}>
                {presence(titleize(row.mac_address))}
              </p>
            </React.Fragment>
          );
        else
          return (
            <p style={{ margin: 0 }}>
              {presence(titleize(row.name || row.mac_address))}
            </p>
          );
      },
    },
    {
      title: "Device Type",
      align: "center",
      render: (row) => {
        return <p>{presence(titleize(row.device_type))}</p>;
      },
    },
    {
      title: "Softwares",
      align: "center",
      width: "15%",
      render: (row) => {
        let ver = row.software?.version || "";
        let cur_ver = row.current_version || "";
        return (
          <React.Fragment>
            {ver === cur_ver ? (
              <div>
                <p style={{ margin: 0, color: "green" }}>{ver}</p>
              </div>
            ) : (
              <div>
                <p style={{ margin: 0, color: "red" }}>
                  {cur_ver ? cur_ver : "-"}
                </p>
                <p style={{ margin: 0, color: "blue" }}>{ver ? ver : "-"}</p>
              </div>
            )}
          </React.Fragment>
        );
      },
    },
    {
      title: "Last Active",
      dataIndex: "last_data_at",
      align: "center",
      width: "15%",
      render: (last_data_at) => {
        return (
          <React.Fragment>
            {last_data_at ? (
              <Tooltip title={formatTime(last_data_at)}>
                {timeDiff(moment(), last_data_at)} ago
              </Tooltip>
            ) : (
              " - "
            )}
          </React.Fragment>
        );
      },
    },
    {
      title: "Status",
      align: "center",
      width: "10%",
      render: (row) => {
        if (row.status === "active") return statusStyle(row.state);
        else return statusStyle(row.status);
      },
    },
    {
      title: "Manage",
      render: (row) => {
        return (
          <React.Fragment>
            <div>
              <Button
                onClick={() => editDevice(row)}
                disabled={!update_permission}
                style={{
                  margin: "0px 10px 5px 10px",
                }}
              >
                Edit
              </Button>

              <Button
                type="primary"
                onClick={() => aboutDevice(row)}
                disabled={!show_permission}
                style={{
                  margin: "0px 10px 5px 10px",
                }}
              >
                Details
              </Button>
            </div>
            <div
              style={{
                marginTop: "1px",
              }}
            >
              <Button
                onClick={() => workflowDevice(row)}
                // disabled={!workflow_permission}
                style={{
                  margin: "0px 10px 5px 10px",
                }}
              >
                Workflow
              </Button>
            </div>
          </React.Fragment>
        );
      },
      align: "center",
    },
  ];
};
function statusStyle(state) {
  const style =
    state === "lost_connectivity"
      ? {
          color: "#ffa705",
          borderColor: "#ffa500",
          background: "#fff5e9",
        }
      : state === "working"
      ? {
          color: "#5acf13",
          borderColor: "#47b111",
          background: "#f3fff0",
        }
      : state === "inactive"
      ? {
          color: "#ff0000",
          borderColor: "#ff0000",
          background: "#fff5e9",
        }
      : state === "stopped"
      ? {
          color: "#cf1322",
          borderColor: "#cf1322",
          background: "#fff1f0",
        }
      : {
          color: "#00B7D1 ",
          borderColor: "#36BCCE",
          background: "#EEF8FA",
        };
  return (
    <Tag style={{ ...style }}>{`${titleize(state ? state : "Created")}`}</Tag>
  );
}

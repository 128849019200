import React, { Component } from "react";
import AppBar from "components/AppBar";
import { Row, Col } from "antd";
import { handleResponse } from "components/notifications/handleResponse";
import PeopleTraffic from "./Home/Sections/PeopleTraffic";
import PeopleTile from "./Home/Sections/PeopleTile";
import { peopleCountCamera } from "api/peoplecount";

class PeopleCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cameras: [],
    };
  }

  componentDidMount = () => {
    this.fetchCamera();
  };

  fetchCamera = () => {
    peopleCountCamera()
      .then(({ data }) => {
        this.setState({
          cameras: data,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <AppBar />
        <Row>
          <Col
            xs={0}
            md={24}
            sm={24}
            lg={16}
            style={{ paddingRight: "15px", paddingBottom: "15px" }}
          >
            <PeopleTraffic camera={this.state.cameras} />
          </Col>
          <Col xs={24} md={24} sm={24} lg={8}>
            <PeopleTile camera={this.state.cameras} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default PeopleCount;

import React, { Component } from "react";
import moment from "moment";
import AppBar from "components/AppBar";
import PaginationTable from "components/paginationTables/withRefreshDownload";
import {
  fetchListingData,
  triggerReportDownload,
  aggregation,
} from "api/peopleEntries";
import { Row } from "antd";
import { setCookie, popCookie, debounce, getCookie } from "helpers";
import { withRouter } from "react-router-dom";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
class PeopleEntries extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = {};
    this.previousLoad = false;
    this.currentUrl = this.props.history.location.pathname;
    this.state = {
      vehicle_type: [],
      phone: [],
      name: [],
      employer: [],
      phone_nos: [],
      names: [],
      employers: [],
      data: [],
      other_filter: [],
      device_name: [],
      device_names: [],
      total_entries: 0,
      skeleton: true,
      ...this.initialFilterState(),
      loader: false,
      page: 1,
    };
  }

  initialRequestParams = () => {
    return {
      fromTime: moment
        .utc(
          moment()
            .subtract(7, "days")
            .startOf("day")
        )
        .format(),
      toTime: moment.utc(moment().endOf("day")).format(),
      visitor_type: this.props.childProps.enumerable,
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  initialFilterState = () => {
    let prevStateValues = getCookie("prevStateValues");
    let phone_nos = [];
    let names = [];
    let employers = [];
    let vehicle_type = [];
    let other_filter = [];
    let loader = [];
    let device_names = [];

    if (prevStateValues) {
      let data = JSON.parse(prevStateValues);
      vehicle_type = data.requestParams.vehicle_type;
      phone_nos = data.requestParams.phone;
      names = data.requestParams.name;
      employers = data.requestParams.employer;
      other_filter = data.requestParams.other_filter;
      loader = data.requestParams.loader;
      device_names = data.requestParams.device_name;
    }

    return {
      vehicle_type,
      phone_nos,
      names,
      employers,
      other_filter,
      loader,
      device_names,
    };
  };

  componentDidMount = () => {
    let scrollY = 0;
    let prevStateValues = popCookie("prevStateValues");
    let usePrevStateValues = popCookie("usePrevStateValues");

    if (usePrevStateValues && prevStateValues) {
      let data = JSON.parse(prevStateValues);
      if (data.currentUrl === this.currentUrl) {
        this.requestParams = data.requestParams;
        scrollY = data.scrollY;
        this.previousLoad = true;
      } else {
        this.requestParams = this.initialRequestParams();
      }
    } else {
      this.requestParams = this.initialRequestParams();
    }
    this.autoRefresh();

    setTimeout(function() {
      window.scrollTo({ top: scrollY });
    }, 1000);
  };

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    let prevStateValues = JSON.stringify({
      scrollY: window.scrollY,
      requestParams: this.requestParams,
      currentUrl: this.currentUrl,
    });
    setCookie("prevStateValues", prevStateValues);
  };

  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.fetchTableData();
    this.timer = setTimeout(this.autoRefresh, 10000);
  };

  onDateChange = (from_time, to_time, loader) => {
    this.requestParams.fromTime = from_time;
    this.requestParams.toTime = to_time;
    this.fetchTableData();
    this.setState({ loader: loader });
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  fetchTableData = async () => {
    await fetchListingData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
          loader: false,
          page: 1,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  getAggregation = async () => {
    let params = {
      fromTime: this.requestParams.fromTime,
      toTime: this.requestParams.toTime,
      purpose: this.requestParams.visitor_type,
    };

    await aggregation(params)
      .then(({ data: { phone, name, employer, device_name } }) => {
        let phoneNum =
          phone["phone"]["buckets"] &&
          phone["phone"]["buckets"].map((key) => ({
            label: key.key,
            value: key.key,
          }));
        let visitorName =
          name["name"]["buckets"] &&
          name["name"]["buckets"].map((key) => ({
            label: key.key,
            value: key.key,
          }));
        let visitorEmployer =
          employer["employer"]["buckets"] &&
          employer["employer"]["buckets"].map((key) => ({
            label: key.key,
            value: key.key,
          }));
        let deviceName =
          device_name["device_name"]["buckets"] &&
          device_name["device_name"]["buckets"].map((key) => ({
            label: key.key,
            value: key.key,
          }));

        this.setState({
          name: visitorName,
          phone: phoneNum,
          employer: visitorEmployer,
          device_name: deviceName,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  generateReport = () => {
    triggerReportDownload(this.requestParams, this.state.total_entries)
      .then((data) => {
        success(data.message);
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.visitor_type = this.props.childProps.enumerable;
    this.fetchTableData();
  };

  onRowClick = (data) => {
    let visitor_id = data?.visitor?.id,
      phone = data?.visitor?.phone;
    if (phone) {
      setCookie("visitor_entry_id", visitor_id);
      this.props.history.push({
        pathname: `/outsiders/${phone}`,
      });
    }
  };

  visitFilterChange = (obj) => {
    this.setState({
      phone_nos: obj.phone,
      names: obj.name,
      employers: obj.employer,
      vehicle_type: obj.vehicle_type,
      other_filter: obj.other_filter,
      loader: obj.loader,
      device_names: obj.device_name,
    });

    this.requestParams.phone = obj.phone;
    this.requestParams.name = obj.name;
    this.requestParams.employer = obj.employer;
    this.requestParams.vehicle_type = obj.vehicle_type;
    this.requestParams.page = obj.page;
    this.requestParams.device_name = obj.device_name;
    this.requestParams.other_filter =
      obj.other_filter && obj.other_filter.length > 0 ? true : false;
    this.debounceSearch();
  };

  render() {
    let { childProps } = this.props;
    const { data, skeleton, loading, loader } = this.state;

    const {
      onChange,
      onDateChange,
      fetchTableData,
      generateReport,
      onRowClick,
      visitFilterChange,
      getAggregation,
    } = this;

    const tableProps = {
      ...childProps,
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      triggerRefresh: fetchTableData,
      generateReport: generateReport,
      onRowClick: onRowClick,
      skeleton,
      loading,
      loader,
    };

    const appBarProps = {
      fromTime: moment(this.requestParams.fromTime),
      toTime: moment(this.requestParams.toTime),
      search: this.requestParams.search,
      dateFilter: true,
      searchOpen: false,
      visitFilter: true,
      onChange,
      onDateChange,
      visitFilterChange,
      getAggregation,
      shortcut: {
        "This Week": {
          start: moment().startOf("week"),
          end: moment().endOf("week"),
        },
        "Last Week": {
          start: moment()
            .subtract(1, "weeks")
            .startOf("week"),
          end: moment()
            .subtract(1, "weeks")
            .endOf("week"),
        },
        "This Month": {
          start: moment().startOf("month"),
          end: moment().endOf("month"),
        },
      },
      visitFilterObj: {
        vehicle_type: this.state.vehicle_type,
        phone: this.state.phone,
        phone_nos: this.state.phone_nos,
        name: this.state.name,
        names: this.state.names,
        employer: this.state.employer,
        employers: this.state.employers,
        other_filter: this.state.other_filter,
        page: this.state.page,
        device_name: this.state.device_name,
        device_names: this.state.device_names,
      },
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
      </React.Fragment>
    );
  }
}

export default withRouter(PeopleEntries);

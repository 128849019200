import React, { Component } from "react";
import { createAnnouncements, updateAnnouncements } from "api/announcements";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";
import Form from "./Form";
// import { fetchBlockFloorUnit } from "api/unit";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      data: {
        block: {},
        floor: {},
        message: "",
      },
      floorData: [],
      blockData: [],
      blockFloorData: [],
    };
  }

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    let { data, blockFloorData } = this.state;
    data[name] = value;
    let floorData = [];
    if (name === "block" && value["value"] !== "all_block") {
      blockFloorData.forEach((ite) => {
        if (ite.block === value.value) {
          floorData = ite["floors"].map((floor) => ({
            value: floor["floor"],
            label: floor["floor"],
          }));
        }
      });
      floorData.splice(0, 0, { label: "All Floors", value: "all_floors" });
      this.setState({ data, floorData });
    } else if (name === "block" && value["value"] === "all_block") {
      data["floor"] = {};
      this.setState({ data });
    } else {
      this.setState({ data });
    }
  };

  requestParams = () => {
    const {
      data: { floor, block, message },
    } = this.state;
    let blockValue = block && block.value;
    let floorValue = floor && floor.value === "all_floors" ? "" : floor.value;
    let location = blockValue + (floorValue ? `${-floorValue}` : "");
    return {
      announcements: {
        location,
        message,
      },
    };
  };

  onSubmit = () => {
    const { id, props } = this;
    props.submitClick();
    const method = !id ? createAnnouncements : updateAnnouncements;
    method(this.requestParams(), id)
      .then((data) => {
        success(data.message);
        this.props.handleSubmitSuccess();
        props.submitClick();
      })
      .catch((error) => {
        props.submitClick();
        handleResponse(error);
      });
  };

  fetchBlockFloor = async () => {
    // await fetchBlockFloorUnit({ announcement: true })
    //   .then(({ blocks }) => {
    //     const blockData = blocks.map(block => ({
    //       value: block["block"],
    //       label: block["block"]
    //     }));
    //     blockData.splice(0, 0, { label: "All Block", value: "all_block" });
    //     if (this.props.data) {
    //       const { location, message } = this.props.data;
    //       let block = {
    //         label: location.split("-")[0],
    //         value: location.split("-")[0]
    //       };
    //       let floor = location.split("-")[1]
    //         ? { label: location.split("-")[1], value: location.split("-")[1] }
    //         : { label: "All Floors", value: "all_floors" };
    //       this.id = this.props.data.id;
    //       let floorData = [];
    //       if (floor.value) {
    //         blocks.forEach(ite => {
    //           if (ite.block === block.value) {
    //             floorData = ite["floors"].map(floor => ({
    //               value: floor["floor"],
    //               label: floor["floor"]
    //             }));
    //           }
    //         });
    //         floorData.splice(0, 0, {
    //           label: "All Floors",
    //           value: "all_floors"
    //         });
    //       }
    //       data["floor"] = floor || {};
    //       data["block"] = block || {};
    //       data["message"] = message;
    //       this.setState({ data, floorData, blockFloorData: blocks, blockData });
    //     } else {
    //       this.setState({ blockFloorData: blocks, blockData });
    //     }
    //   })
    //   .catch(error => {
    //     handleResponse(error);
    //   });
  };

  componentDidMount = () => {
    this.fetchBlockFloor();
  };

  render() {
    const { handleChange } = this;
    const {
      data: { message, block, floor },
      blockData,
      floorData,
    } = this.state;
    const formProps = {
      message,
      block,
      floor,
      handleChange,
      blockData,
      floorData,
    };
    return <Form {...formProps} />;
  }
}
export default DialogForm("Announcement", "800")(CRUD);

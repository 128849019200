import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import { Table, Skeleton } from "antd";
import TableHeader from "./tableHeader";
import { columnBlock, columnFloor, columnUnit } from "data/units";
import { fetchResidentDetails } from "api/unit";
import { handleResponse } from "components/notifications/handleResponse";

class TableExpands extends Component {
  constructor(props) {
    super(props);
    this.state = {
      residentData: [],
    };
  }

  fetchResidentData = (block, floor) => {
    fetchResidentDetails(block, floor)
      .then(({ data }) => {
        this.setState({
          residentData: data,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  componentDidMount() {
    this.fetchResidentData(this.props.block, this.props.floor);
  }

  render() {
    const { columns } = this.props;
    const { residentData } = this.state;
    return (
      <>
        {residentData.length !== 0 ? (
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={residentData}
            pagination={false}
          />
        ) : (
          <Skeleton
            style={{ width: "100%", padding: "10px 50px" }}
            active={true}
            title={false}
            paragraph={{
              rows: 10,
            }}
          />
        )}
      </>
    );
  }
}

class TableExpand extends Component {
  expandedUnitRender = (row) => {
    return (
      <TableExpands
        data={row.units}
        columns={columnUnit(this.props.aboutUnit)}
        floor={row.floor}
        block={this.props.block}
      />
    );
  };

  render() {
    const { columns, data } = this.props;
    return (
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={data}
        pagination={false}
        // expandedRowRender={expandedUnitRender}
      />
    );
  }
}

class UnitTable extends Component {
  expandedRowRender = (row) => {
    return (
      <TableExpand
        data={row.floors}
        columns={columnFloor}
        block={row.block}
        aboutUnit={this.props.aboutUnit}
      />
    );
  };

  render() {
    const { expandedRowRender, props } = this;
    const {
      tabColor,
      title,
      normalIcon,
      subTitle,
      data,
      handleOnClick,
      dataValue,
      onRowClick,
      createAction,
    } = props;

    const headerprops = {
      tabColor,
      normalIcon,
      title,
      subTitle,
      handleOnClick,
      dataValue,
      onRowClick,
      createAction,
    };
    return (
      <Paper className="paperClass">
        <TableHeader {...headerprops} />
        <div
          style={{
            overflowX: "auto",
            width: "100%",
          }}
        >
          <Table
            rowKey={(record) => record.id}
            className="components-table-demo-nested"
            columns={columnBlock}
            expandedRowRender={expandedRowRender}
            dataSource={data}
            pagination={false}
          />
        </div>
      </Paper>
    );
  }
}
export default UnitTable;

import React, { Component } from "react";
import { uploadBulkContractor } from "api/cardRegister";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";
import { Row, Col, Button, Icon, Upload } from "antd";
import { CSVLink } from "react-csv";

const dummyRequest = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const Sample = () => {
  const contractorData = [
    ["name", "phone", "fin_number", "send_welcome", "employer", "expire_at"],
    ["Adeline ", "87767767", "XXXXX1234", "true", "hdb", "01/4/2022"],
    ["Asher ", "87767768", "XXXXX1235", "true", "hdb", "14/4/2022"],
    ["Daniel", "87767769", "XXXXX1236", "false", "hdb", "25/4/2022"],
    ["Darryl", "87767780", "XXXXX1239", "false", "hdb", "30/7/2022"],
  ];

  return (
    <Button>
      <Icon type="download" style={{ color: "black", marginRight: "5px" }} />
      <CSVLink
        data={contractorData}
        uFEFF={false}
        filename={"contractor-sample.csv"}
      >
        Download
      </CSVLink>
    </Button>
  );
};

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      open: false,
      selectedFile: null,
      selectedFileList: [],
      buttonDisable: true,
    };
  }

  requestParams = () => {
    const { selectedFile } = this.state;
    if (!selectedFile || !selectedFile["originFileObj"]) {
      return null;
    }
    let data = new FormData();
    data.append("import", selectedFile["originFileObj"]);
    return data;
  };

  onSubmit = () => {
    let data = this.requestParams();
    uploadBulkContractor(data)
      .then((data) => {
        success(data.message);
        this.props.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  onChangeHandler = (info) => {
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        nextState.selectedFileList = [info.file];
        break;
      case "done":
        nextState.selectedFile = info.file;
        nextState.selectedFileList = [info.file];
        this.setState({ buttonDisable: false });
        break;
      default:
        nextState.selectedFile = null;
        nextState.selectedFileList = [];
        this.setState({ buttonDisable: true });
    }
    this.setState(() => nextState);
  };

  render() {
    const { props } = this;

    return (
      <React.Fragment>
        <Row>
          <Col
            xs={24}
            sm={12}
            md={12}
            style={{ padding: "10px", textAlign: "center" }}
          >
            <p>Sample Data Format</p>
            <Sample />
          </Col>

          <Col
            xs={24}
            sm={12}
            md={12}
            style={{ padding: "10px", textAlign: "center" }}
          >
            <p>Upload Data</p>
            <Upload
              customRequest={dummyRequest}
              onChange={this.onChangeHandler}
              fileList={this.state.selectedFileList}
              style={{ width: "100%" }}
              accept={".csv"}
            >
              <Button style={{ color: "#1890ff" }}>
                <Icon type="upload" style={{ color: "black" }} />
                Upload
              </Button>
            </Upload>
            {!props?.disabled && (
              <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0 }}
                value={this.state.selectedFile}
                required
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={24} style={{ padding: "10px" }}>
            <p style={{ textAlign: "center" }}>
              *kindly check the <b>format</b> before uploading the data
            </p>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default DialogForm()(CRUD);

import React, { Component } from "react";
import { presence } from "helpers";
import { handleResponse } from "components/notifications/handleResponse";
import { peopleCountTiles } from "api/peoplecount";
import { Card, CardHeader } from "material-dashboard-react/components";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { blue } from "@material-ui/core/colors";

class PeopleTile extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      data: [],
      cameras: [],
    };
  }

  componentDidMount() {
    this.autoRefresh();
  }

  fetchTableData = () => {
    peopleCountTiles()
      .then(({ data }) => {
        this.setState({ data });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.fetchTableData();
    this.timer = setTimeout(this.autoRefresh, 1000 * 60 * 2);
  };

  render() {
    const { classes, camera } = this.props;
    const { data } = this.state;
    return (
      <Card style={{ marginBottom: "10px", minHeight: "479px" }}>
        <CardHeader
          style={{
            marginTop: " -20px",
            marginRight: " 20px",
            marginLeft: "20px",
            borderRadius: "3px",
            boxShadow: "#42A4F4 -1px 7px 8px -5px",
            background: `linear-gradient(60deg, ${blue[600]}, ${blue[400]})`,
          }}
        >
          <div>
            <h4
              style={{
                margin: "2px 0px 5px",
                fontSize: "1.3em",
                fontWeight: "300",
                color: "white",
              }}
            >
              People Count
            </h4>
            <p
              style={{
                margin: "0px 0px 5px",
                color: "white",
                fontWeight: "300",
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
              }}
            >
              Monitoring People Count for Today
            </p>
          </div>
        </CardHeader>

        {data?.map((people, i) =>
          camera.map(
            (cam) =>
              cam.id === people.camera_id && (
                <>
                  <br></br>
                  <div
                    style={{
                      display: "flex",
                      paddingLeft: "15px",
                      marginTop: "30px",
                    }}
                  >
                    <span
                      style={{
                        width: "3px",
                        marginRight: "10px",
                        background: "#208ae6",
                        fontFamily:
                          "'Roboto', 'Helvetica', 'Arial', sans-serif",
                      }}
                    ></span>
                    <h3 className="report_sub_heading">{cam.name}</h3>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      padding: "5px 0px",
                    }}
                  >
                    <h3
                      style={{
                        padding: "10px 0px",
                        minHeight: 20,
                        minWidth: "50%",
                      }}
                      className={classes.cardTitle}
                    >
                      People Entered
                    </h3>
                    <h2
                      style={{
                        padding: "6px 13px",
                        minHeight: 20,
                        color: "white",
                        background: "#d25555",
                        fontWeight: "600",
                        borderRadius: "5px",
                        height: "42px",
                        minWidth: "60px",
                        textAlign: "center",
                      }}
                      className={classes.cardTitle}
                    >
                      {presence(people?.enteredtoday)}
                    </h2>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      padding: "5px 0px",
                      marginBottom: "-30px",
                    }}
                  >
                    <h3
                      style={{
                        padding: "10px 0px",
                        minHeight: 20,
                        minWidth: "50%",
                      }}
                      className={classes.cardTitle}
                    >
                      People Exited
                    </h3>
                    <h2
                      style={{
                        padding: "6px 13px",
                        minHeight: 20,
                        color: "white",
                        background: "#54b235",
                        fontWeight: "600",
                        borderRadius: "5px",
                        height: "42px",
                        minWidth: "60px",
                        textAlign: "center",
                      }}
                      className={classes.cardTitle}
                    >
                      {presence(people?.exitedtoday)}
                    </h2>
                  </div>
                </>
              )
          )
        )}
      </Card>
    );
  }
}
export default withStyles(dashboardStyle)(PeopleTile);

import React, { Component } from "react";
import Form from "./Form";
import { createUser, updateUser, logOut } from "api/users";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import { fetchRoles } from "api/roles";
import DialogForm from "hoc/dialogForm";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      open: false,
      rolesRequired: null,
      data: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        userRoles: [],
        botRoles: [],
        send_welcome: true,
        status: "active",
        type: "User",
        country_code: 65,
        auth_token: "",
        read_only: false,
      },
    };
  }

  handleChange = (e) => {
    let { data } = this.state;
    let { name, value } = e.target;
    data[name] = value;
    this.setState({ data });
    if (name === "userRoles") {
      this.setState({
        rolesRequired: !this.state.data.userRoles.length && null,
      });
    }
    if (name === "botRoles") {
      this.setState({
        rolesRequired: !this.state.data.botRoles.length && null,
      });
    }
    if (name === "type") {
      this.setState({
        open: false,
        rolesRequired: null,
        data: {
          first_name: "",
          last_name: "",
          phone: "",
          email: "",
          userRoles: [],
          botRoles: [],
          send_welcome: true,
          status: "active",
          type: data[name],
          country_code: 65,
          auth_token: "",
          read_only: false,
        },
      });
    }
  };

  requestParams = () => {
    const {
      userRoles,
      botRoles,
      type,
      send_welcome,
      ...rest
    } = this.state.data;

    let role_ids = [];
    //handling data
    if (Array.isArray(userRoles)) {
      if (type === "User" && userRoles) {
        userRoles.map((a) => role_ids.push(a.value));
      }
    } else {
      if (type === "User" && userRoles) {
        role_ids.push(userRoles.value);
      }
    }

    if (Array.isArray(botRoles)) {
      botRoles.map((a) => role_ids.push(a.value));
    } else {
      role_ids.push(botRoles.value);
    }
    return {
      ...rest,
      send_welcome: type === "Bot" ? false : send_welcome,
      role_ids,
      type,
    };
  };

  onSubmit = () => {
    const { id, props } = this;
    const { botRoles, userRoles } = this.state.data;
    const method = !id ? createUser : updateUser;
    if (Object.keys(userRoles).length > 0 || Object.keys(botRoles).length > 0) {
      method({ users: this.requestParams() }, id)
        .then((data) => {
          success(data.message);
          props.handleSubmitSuccess();
        })
        .catch((error) => {
          handleResponse(error);
        });
    } else {
      this.setState({
        rolesRequired: true,
      });
    }
  };

  fetchRolesData = () => {
    fetchRoles({ type: "UserRole" })
      .then(({ data }) => {
        this.setState({
          rolesData: data.map((role) => ({
            label: role.name,
            value: role.id,
          })),
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  fetchBotRolesData = () => {
    fetchRoles({ type: "BotRole" })
      .then(({ data }) => {
        this.setState({
          rolesBotData: data.map((role) => ({
            label: role.name,
            value: role.id,
          })),
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    this.fetchRolesData();
    this.fetchBotRolesData();
    if (this.props.data) {
      const {
        id,
        first_name,
        last_name,
        email,
        phone,
        roles,
        status,
        type,
        country_code,
        auth_token,
        read_only,
      } = this.props.data;
      this.id = id;
      this.setState({
        data: {
          ...this.state.data,
          first_name,
          last_name,
          email,
          phone,
          userRoles:
            type === "User"
              ? roles.map((role) => ({ label: role.name, value: role.id }))
              : "",
          botRoles:
            type === "Bot"
              ? roles.map((role) => ({ label: role.name, value: role.id }))
              : "",
          status,
          type,
          country_code,
          auth_token,
          read_only,
        },
      });
    }
  };

  logoutUser = () => {
    logOut(this.id)
      .then((response) => {
        success(response);
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const { id, handleChange } = this;
    const { data, rolesData, rolesBotData, rolesRequired } = this.state;
    return (
      <Form
        {...{
          ...data,
          rolesData,
          rolesBotData,
          handleChange,
          id,
          rolesRequired,
        }}
      />
    );
  }
}
export default DialogForm("Users")(CRUD);

import React, { Component } from "react";
import { Card, CardBody } from "material-dashboard-react/components";
import { Col, Row, Tabs, Table, Popconfirm } from "antd";
import { Button } from "antd";
import { staffColumn, invitesColumn } from "data/staffInfo";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import { getInvites } from "api/inviteVisitor";
import { getStaffEntries, getStaffData, regenerateId } from "api/staffs";
import { titleize, getCookie } from "helpers";
import moment from "moment";
import male_icon from "assets/img/male.png";
import Skeleton from "components/Skeleton";

const { TabPane } = Tabs;

class HeadPages extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();

    this.state = {
      invitationTotal_entries: 0,
      staffTotal_entries: 0,
      total_entries: 0,
      skeleton: true,
      loader: false,
      loading: false,
      page: 1,
      invites: [],
      staffEntries: [],
      staffData: [],
      active_tab: "staff_invite",
    };
  }

  initialRequestParams = () => {
    return {
      fromTime: moment.utc(moment().startOf("day")).format(),
      toTime: moment.utc(moment().endOf("day")).format(),
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  handleTableChange = async (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.setState({ loading: true });
    if (this.state.active_tab === "staff_invite") {
      await this.fetchInvites();
    } else {
      await this.fetchStaffEntries();
    }
  };

  fetchInvites = async () => {
    let id = this.state.staffData.id;
    await getInvites(this.requestParams, id)
      .then(({ data, total_entries }) => {
        this.setState({
          invites: data,
          invitationTotal_entries: total_entries,
          skeleton: false,
          loading: false,
          loader: false,
          total_entries,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };
  fetchStaffEntries = async () => {
    let id = this.state.staffData.id;
    await getStaffEntries(this.requestParams, id)
      .then(({ data, total_entries }) => {
        this.setState({
          staffEntries: data,
          staffTotal_entries: total_entries,
          skeleton: false,
          loading: false,
          loader: false,
          total_entries,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  fetchStaffData = async () => {
    let phone_number = this.props.phone
      ? this.props.phone
      : this.props.phoneUrl;
    await getStaffData(phone_number)
      .then(({ data }) => {
        this.setState({
          staffData: data,
          skeleton: false,
          loading: false,
          loader: false,
        });
      })

      .catch((err) => {
        console.log(err);
        this.props.props_data.history.push(`/${getCookie("home_page")}`);
      });
  };

  componentDidMount = async () => {
    await this.fetchStaffData();
    await this.fetchStaffEntries();
    await this.fetchInvites();
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total:
        state.active_tab === "staff_invite"
          ? state.invitationTotal_entries
          : state.employeeTotal_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleChange = (e) => {
    this.setState({ active_tab: e.target.value });
  };

  cancel = () => {
    return;
  };
  fetchIdData = () => {
    const id = this.state.staffData.id;
    regenerateId(id)
      .then(({ data, message }) => {
        this.setState({
          staffData: data,
        });
        success(message);
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const { skeleton, staffData, loading, loader } = this.state;
    const staffImage = staffData?.face_id_image?.s3_image_path;
    const {
      pagination,
      handleTableChange,
      handleChange,
      cancel,
      fetchIdData,
    } = this;

    return (
      <React.Fragment>
        <Card style={{ margin: 0 }}>
          <CardBody>
            <Row
              xs={24}
              sm={24}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Col xs={9} sm={9}>
                <div style={{ display: "flex" }}>
                  <div>
                    {staffImage ? (
                      <div
                        className="image-cropper"
                        style={{ marginTop: "10px", marginLeft: "0px" }}
                      >
                        <img
                          src={staffImage}
                          alt="avatar"
                          className="profile-pic"
                        />
                      </div>
                    ) : (
                      <img
                        src={male_icon}
                        style={{ maxWidth: 116, borderRadius: "50%" }}
                        alt="staff-images"
                      />
                    )}
                  </div>
                  <div style={{ padding: "1rem", display: "flex" }}>
                    <div>
                      <Row style={{ fontSize: 28, fontWeight: "bolder" }}>
                        {titleize(staffData?.name)}
                      </Row>
                      <Row
                        style={{ paddingTop: ".3rem", paddingLeft: ".7rem" }}
                      >
                        {staffData?.phone}
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={8} sm={8}>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "grey",
                  }}
                >
                  {staffData?.department}
                </span>
              </Col>
              <Col xs={7} sm={7}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Popconfirm
                    title="Do you want to regenerate ID?"
                    placement="left"
                    onConfirm={fetchIdData}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button style={{ background: "#ff4d4f", color: "white" }}>
                      Re-Generate Id
                    </Button>
                  </Popconfirm>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {/* Invitation tab */}
        <Tabs
          defaultActiveKey="1"
          style={{
            marginTop: "20px",
            background: "#ffffff",
            boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
            borderRadius: "6px",
          }}
          onChange={(value) =>
            handleChange({ target: { value, name: "about_staff" } })
          }
        >
          <TabPane
            tab={
              <span>
                Invitations
                {this.state.invitationTotal_entries !== 0
                  ? `(${this.state.invitationTotal_entries})`
                  : ""}
              </span>
            }
            key="staff_invite"
            style={{
              color: "#000000",
              background: "#ffffff",
            }}
          >
            {skeleton ? (
              <Skeleton />
            ) : (
              <Table
                columns={invitesColumn}
                dataSource={this.state.invites}
                onChange={handleTableChange}
                style={{ background: "#ffffff" }}
                pagination={
                  this.state.invitationTotal_entries !== 0 && {
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} results`,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "25", "50", "100"],
                    ...pagination(),
                  }
                }
                loading={loading || loader}
              />
            )}
          </TabPane>
          <TabPane
            tab={
              <span>
                Staff Entries
                {this.state.staffTotal_entries !== 0
                  ? `(${this.state.staffTotal_entries})`
                  : ""}
              </span>
            }
            key="staff_entry"
            style={{ color: "#000000", background: "#ffffff" }}
          >
            {skeleton ? (
              <Skeleton />
            ) : (
              <Table
                columns={staffColumn}
                dataSource={this.state.staffEntries}
                onChange={handleTableChange}
                style={{ background: "#ffffff" }}
                pagination={
                  this.state.Total_entries !== 0 && {
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} results`,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "25", "50", "100"],
                    ...pagination(),
                  }
                }
                loading={loading || loader}
              />
            )}
          </TabPane>
        </Tabs>
      </React.Fragment>
    );
  }
}

export default HeadPages;

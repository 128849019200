import React, { Component } from "react";
import { debounce } from "helpers";
import { columns } from "data/registerCard";
import AppBar from "components/AppBar";
import { fetchRegisteredData, aggregation } from "api/cardRegister";
import { BsUpload } from "react-icons/bs";
import PaginationTable from "components/paginationTables/withAddEdit";
import CRUDRegister from "components/form/registerCard/CRUD";
import { Row } from "antd";
import { handleResponse } from "components/notifications/handleResponse";
import {
  createAccess,
  updateAccess,
  uploadAccess,
  popCookie,
  setCookie,
} from "helpers";
import CRUDUpload from "components/form/registerCard/CRUDUpload";

class CardRegister extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      name: [],
      employers: [],
      employer: [],
      phone: [],
      phone_nos: [],
      staffsname: [],
      total_entries: 0,
      registeredData: undefined,
      formOpen: false,
      skeleton: true,
      upload: false,
      status: ["active", "inactive"],
      statuses: [],
    };
  }

  initialRequestParams = () => {
    return {
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  componentDidMount = () => {
    let scrollY = 0;
    let prevStateValues = popCookie("prevStateValues");
    let usePrevStateValues = popCookie("usePrevStateValues");
    if (usePrevStateValues && prevStateValues) {
      let data = JSON.parse(prevStateValues);
      if (data.currentUrl === this.currentUrl) {
        this.requestParams = data.requestParams;
        scrollY = data.scrollY;
        this.previousLoad = true;
      } else {
        this.requestParams = this.initialRequestParams();
      }
    } else {
      this.requestParams = this.initialRequestParams();
    }
    this.fetchTableData();
    setTimeout(function() {
      window.scrollTo({ top: scrollY });
    }, 1000);
  };

  flushTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.flushTimer();

    let prevStateValues = JSON.stringify({
      scrollY: window.scrollY,
      requestParams: this.requestParams,
      currentUrl: this.currentUrl,
    });
    setCookie("prevStateValues", prevStateValues);
  };

  fetchTableData = () => {
    fetchRegisteredData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          skeleton: false,
          total_entries,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  getAggregation = async () => {
    await aggregation()
      .then(({ data: { name, phone, employer } }) => {
        let staffname = name.map((key) => ({
          label: key,
          value: key,
        }));
        let phoneNum = phone.map((key) => ({
          label: key,
          value: key,
        }));
        let emp = employer.map((key) => ({
          label: key,
          value: key,
        }));
        let status = [
          { label: "Active", value: "active" },
          { label: "InActive", value: "inactive" },
        ];
        this.setState({
          name: staffname,
          employer: emp,
          phone: phoneNum,
          status,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    setTimeout(() => {
      this.fetchTableData();
    }, 3000);
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      RegisteredData: undefined,
      upload: false,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  editRegisteredCard = (row) => {
    let access = this.updateAction();
    if (access) {
      this.setState({ formOpen: true, RegisteredData: row });
    }
  };

  handleTableChange = (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.fetchTableData();
  };

  createAction = () => {
    return createAccess("contractors");
  };

  updateAction = () => {
    return updateAccess("contractors");
  };
  uploadAction = () => {
    return uploadAccess("contractors");
  };

  StaffsFilterChange = (obj) => {
    this.setState({
      phone_nos: obj.phone,
      staffsname: obj.name,
      employers: obj.employer,
      statuses: obj.status,
    });
    this.requestParams.phone = obj.phone;
    this.requestParams.name = obj.name;
    this.requestParams.employer = obj.employer;
    this.requestParams.status = obj.status;
    this.debounceSearch();
  };

  render() {
    const { data, RegisteredData, formOpen, skeleton, upload } = this.state;
    const {
      onChange,
      handleClose,
      handleSubmitSuccess,
      handleTableChange,
      editRegisteredCard,
      getAggregation,
      StaffsFilterChange,
    } = this;

    const CRUDProps = {
      onCancel: handleClose,
      open: formOpen,
      data: RegisteredData,
      handleSubmitSuccess,
      tabColor: "warning",
    };

    const appBarProps = {
      search: this.requestParams.search,
      searchOpen: false,
      staffsFilter: true,
      type: "regContractor",
      onChange,
      getAggregation,
      StaffsFilterChange,
      staffsFilterObj: {
        name: this.state.name,
        phone: this.state.phone,
        employers: this.state.employers,
        employer: this.state.employer,
        staffsname: this.state.staffsname,
        phone_nos: this.state.phone_nos,
        statuses: this.state.statuses,
        status: this.state.status,
      },
    };

    const tableProps = {
      skeleton,
      normalIcon: <BsUpload />,
      tabColor: "warning",
      title: "Registered Contractor",
      subTitle: "Registered Contractor ",
      pagination: this.pagination(),
      columns: columns(editRegisteredCard),
      data: data,
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      OnClickUpload: () => {
        this.setState({ upload: true });
      },
      onRowClick: () => {},
      dataValue: "Add Contractors",
      dataValueUpload: "Upload",
      createAction: this.createAction(),
      uploadAction: this.uploadAction(),
      handleTableChange,
    };

    const CRUDContractorUploadProps = {
      open: upload,
      titleText: "Contractor Data",
      onCancel: handleClose,
      handleSubmitSuccess,
      upload: true,
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        {formOpen && <CRUDRegister {...CRUDProps} />}
        <CRUDUpload {...CRUDContractorUploadProps} />
      </React.Fragment>
    );
  }
}

export default CardRegister;

import makeRequest from ".";

function generateParams(params = {}) {
  let Name = "";
  let phone_no = "";
  let emp = "";
  let statuses = "";

  const { name, phone, employer, status } = params;

  if (status) {
    statuses = status.map((key) => `&status[]=${key.value}`).join("");
  }
  if (name) {
    Name = name.map((value) => `&name[]=${value.label}`).join("");
  }
  if (phone && phone.length > 0) {
    phone_no = phone.map((key) => `&phone[]=${key.label}`).join("");
  }

  if (employer && employer.length > 0) {
    emp = employer.map((key) => `&employer[]=${key.label}`).join("");
  }
  return `${Name}${phone_no}${statuses}${emp}&query=${
    params.search
  }&page=${params.page || 1}&per_page=${params.perPage}`;
}

export function fetchRegisteredData(requestParams) {
  return makeRequest({
    uri: `/api/v1/contractors?${generateParams(requestParams)}`,
  });
}
export function createContractors(requestBody) {
  return makeRequest({
    uri: `/api/v1/contractors`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateContractors(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/contractors/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function aggregation() {
  return makeRequest({
    uri: `/api/v1/contractors/aggs`,
    method: "GET",
  });
}

export function uploadBulkContractor(body) {
  return makeRequest({
    uri: `/api/v1/contractors/import`,
    method: "POST",
    body,
  });
}

import React, { Component } from "react";
import { createEmployee, updateEmployee } from "api/employees";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";
import Form from "./Form";
class CRUD extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.id = undefined;
    this.access = {
      rfid: "",
      status: "active",
    };
    this.state = {
      open: false,
      data: {
        name: "",
        phone: "",
        department: "",
        status: "active",
        tabActiveKey: "Details",
        access_cards: [{ ...this.access }],
      },
    };
  }

  handleChange = (event, index) => {
    const { data } = this.state;
    if (index !== undefined) {
      this.setState({ data });
    } else {
      data[event.target.name] = event.target.value;
      this.setState({ data });
    }
  };

  rfidChange = (event, index) => {
    let card = this.state.data.access_cards;
    if (index === card.length - 1) {
      if (!card[index].rfid) {
        card.push({
          rfid: "",
          status: "active",
        });
      }
    }
    card[index][event.target.name] = event.target.value;
    if (card[index].rfid) {
      this.setState({ access_cards: card });
    } else {
      card.length > 1 && card.pop();
      this.setState({ access_cards: card });
    }
  };

  requestParams = () => {
    const {
      data: { access_cards, ...rest },
    } = this.state;

    let rfid_cards = [];

    access_cards &&
      access_cards.forEach((card) => {
        if (card.rfid.length !== 0) {
          rfid_cards.push(card);
        }
      });

    let create_employee = {
      employees: {
        ...rest,
        access_cards: rfid_cards,
      },
    };

    let update_employee = {
      employees: {
        ...rest,
        access_cards: rfid_cards,
      },
    };

    if (this.props?.data?.id) {
      return update_employee;
    } else if (!this.props.data) {
      return create_employee;
    } else {
      return create_employee;
    }
  };

  onSubmit = () => {
    const { id, props } = this;
    props.submitClick();
    const method = !id ? createEmployee : updateEmployee;
    method(this.requestParams(), id)
      .then((data) => {
        success(data.message);
        this.props.handleSubmitSuccess();
        props.submitClick();
      })
      .catch((error) => {
        props.submitClick();
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    let access_card = this.props?.data?.access_cards || [];
    let temp = access_card && access_card.filter((c) => c.rfid !== "");
    temp.push(this.access);

    if (this.props.data) {
      const { id, name, phone, status, department } = this.props.data;
      this.id = id;
      this.setState({
        data: {
          ...this.state.data,
          name,
          phone,
          status,
          department,
          access_cards: temp,
        },
      });
    }
    // searchUnitData({ search: unit_number }).then(val => {
    //   let units = val.data.map(value => ({
    //     label: value.unit_number,
    //     value: value.id
    //   }));
    //   const { data } = this.state;
    //   data["unit_number"] = units;
    //   this.setState({ data });
    // });
  };

  onDelete = () => {
    this.setState({ open: true });
  };

  cancelDelete = () => {
    this.setState({ open: false });
  };

  render() {
    const { handleChange, onCreate, rfidChange, addNew } = this;

    const { dateFilter } = this.props;
    const {
      data: { ...rest },
    } = this.state;
    const EmployeeProps = {
      dateFilter,
      ...rest,
      handleChange,
      handleCreate: onCreate,
      rfidChange,
      id: this.id,
      addNew,
    };
    return <Form {...EmployeeProps} />;
  }
}
export default DialogForm()(CRUD);

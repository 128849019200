import React, { Component } from "react";
import clsx from "clsx";
import moment from "moment";
import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  IconButton,
  CssBaseline,
  withStyles,
} from "@material-ui/core";
import { MdMenu, MdChevronLeft } from "react-icons/md";
import Profile from "components/Profile";
import DateTimePicker from "components/dateTimePicker";
import SearchBar from "components/SearchBar";
import { siteName } from "config";
import { AppBarContext, TableContext } from "context/ContextProvider";
import DateSelector from "components/DatePicker";
import PropTypes from "prop-types";
import { FaFilter } from "react-icons/fa";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Button } from "antd";
import FilterCard from "./Filtercard";
import LNPRFilter from "./LNPRFilter";
import VisitAlerts from "./VisitAlerts";
import { getCookie } from "helpers";
import StaffsFilter from "./StaffsFilter";

const drawerWidth = 240;

const useStyles = (theme) => ({
  filter: {
    position: "relative",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    margin: 0,
    alignItems: "center",
    display: "flex",
    padding: "10px 19px 10px 30px",
    borderLeft: "1px solid lightgray",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  root: {
    display: "flex",
    zIndex: "0",
  },
  button: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    color: "rgb(108, 92, 231)",
    marginRight: theme.spacing(2),
  },
  title: {
    color: "rgb(108, 92, 231)",
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
});

class MyAppBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: this.props.date,
      filter: this.props.filter,
      visitFilter: this.props.visitFilter,
      staffsFilter: this.props.staffsFilter,
      alertFilter: false,
      vFilter: false,
      lnprFilter: false,
      sfilter: false,
      filterColor: "gray",
      loader: true,
    };
  }

  dateChange = (from_time, to_time) => {
    this.setState({
      dateField: false,
      loader: true,
      filterColor: from_time === "" ? "gray" : "#2182f0",
    });
    this.props.onDateChange(
      moment.utc(from_time).format(),
      moment.utc(to_time).format(),
      this.state.loader
    );
  };

  alertFilterChange = ({ fromTime, toTime, alert_type, status, loader }) => {
    this.setState({
      dateField: false,
      filterColor: fromTime === "" ? "gray" : "#2182f0",
    });
    let data = { fromTime, toTime, alert_type, status, loader };
    this.props.filterChange(data);
  };

  toggleAlertFilterShow = () => {
    this.setState({ alertFilter: !this.state.alertFilter });
  };

  filterOnClick = () => {
    this.setState({ lnprFilter: !this.state.lnprFilter });
    this.props.getAggregation();
  };

  visitFilterOnClick = () => {
    this.setState({ vFilter: !this.state.vFilter });
    this.props.getAggregation();
  };
  staffFilterOnClick = () => {
    this.setState({ sfilter: !this.state.sfilter });
    this.props.getAggregation();
  };
  openToNewTab = () =>
    window.open(`${window.location.origin}/${getCookie("home_page")}`);

  render() {
    const {
      classes,
      dateFilter,
      searchOpen,
      fromTime,
      toTime,
      shortcut,
      search,
      onChange,
      filterObj,
      filterChange,
      visitFilterObj,
      visitFilterChange,
      StaffsFilterChange,
      staffsFilterObj,
    } = this.props;

    const {
      filterOnClick,
      visitFilterOnClick,
      toggleAlertFilterShow,
      alertFilterChange,
      staffFilterOnClick,
    } = this;

    const filterProp = {
      shortcut,
      filterChange,
      alertFilterChange,
      toggleAlertFilterShow,
      ...filterObj,
    };

    const lnprFilterProp = { filterChange, filterOnClick, ...filterObj };

    const visitFilterProp = {
      visitFilterChange,
      visitFilterOnClick,
      ...visitFilterObj,
    };

    const staffsFilterProp = {
      StaffsFilterChange,
      staffFilterOnClick,
      ...staffsFilterObj,
    };

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar)}
          style={{ zIndex: 10 }}
        >
          <Toolbar>
            <Grid container>
              <Grid item xs={4} sm={4} md={4} lg={4} style={{ minHeight: 60 }}>
                <Grid
                  container
                  justify="flex-start"
                  alignItems="center"
                  style={{ height: "100%" }}
                >
                  <AppBarContext.Consumer>
                    {({ menuOpen, handleDrawer }) => (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="Open drawer"
                        className={clsx(classes.menuButton)}
                        onClick={(e) => {
                          if (e.ctrlKey) {
                            this.openToNewTab();
                          } else {
                            handleDrawer(e);
                          }
                        }}
                        onMouseDown={(e) => {
                          if (e.button === 1) {
                            this.openToNewTab();
                          }
                        }}
                      >
                        {menuOpen ? <MdChevronLeft /> : <MdMenu />}
                      </IconButton>
                    )}
                  </AppBarContext.Consumer>
                  <Typography
                    variant="h4"
                    color="inherit"
                    className={classes.title}
                    style={{ fontSize: 25, cursor: "pointer" }}
                    onClick={(e) => {
                      if (e.ctrlKey) {
                        this.openToNewTab();
                      }
                    }}
                    onMouseDown={(e) => {
                      if (e.button === 1) {
                        this.openToNewTab();
                      }
                    }}
                  >
                    {siteName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                lg={8}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Grid container justify="flex-end" item={false}>
                  {dateFilter && (
                    <DateTimePicker
                      start={fromTime}
                      end={toTime}
                      shortcut={shortcut}
                      onChange={this.dateChange}
                    />
                  )}
                  {this.state.date && (
                    <Button
                      type="default"
                      shape="omitted"
                      size="large"
                      style={{
                        margin: "0px",
                        color: this.state.filterColor,
                        width: "70px",
                        height: "72px",
                        borderRadius: "0px",
                        border: "none",
                        borderLeft: "1px solid #d3d3d3",
                      }}
                      onClick={this.toggleAlertFilterShow}
                    >
                      <FaFilter
                        style={{ fontSize: "18px", color: "#6b5ce7" }}
                      />
                    </Button>
                  )}
                  {this.state.alertFilter && <FilterCard {...filterProp} />}
                  {this.state.filter && (
                    <Button
                      type="default"
                      shape="omitted"
                      size="large"
                      style={{
                        margin: "0px",
                        color: "#6b5ce7",
                        width: "70px",
                        height: "72px",
                        borderRadius: "0px",
                        border: "none",
                        borderLeft: "1px solid #d3d3d3",
                      }}
                      onClick={this.filterOnClick}
                    >
                      <FaFilter
                        style={{ fontSize: "18px", color: "#6b5ce7" }}
                      />
                    </Button>
                  )}
                  {this.state.visitFilter && (
                    <Button
                      type="default"
                      shape="omitted"
                      size="large"
                      style={{
                        margin: "0px",
                        color: "#6b5ce7",
                        width: "70px",
                        height: "72px",
                        borderRadius: "0px",
                        border: "none",
                        borderLeft: "1px solid #d3d3d3",
                      }}
                      onClick={this.visitFilterOnClick}
                    >
                      <FaFilter
                        style={{ fontSize: "18px", color: "#6b5ce7" }}
                      />
                    </Button>
                  )}
                  {this.state.staffsFilter && (
                    <Button
                      type="default"
                      shape="omitted"
                      size="large"
                      style={{
                        margin: "0px",
                        color: "#6b5ce7",
                        width: "70px",
                        height: "72px",
                        borderRadius: "0px",
                        border: "none",
                        borderLeft: "1px solid #d3d3d3",
                      }}
                      onClick={this.staffFilterOnClick}
                    >
                      <FaFilter
                        style={{ fontSize: "18px", color: "#6b5ce7" }}
                      />
                    </Button>
                  )}
                  {this.state.vFilter && <VisitAlerts {...visitFilterProp} />}
                  {this.state.lnprFilter && <LNPRFilter {...lnprFilterProp} />}
                  {this.state.sfilter && (
                    <StaffsFilter
                      {...staffsFilterProp}
                      type={this.props.type}
                    />
                  )}
                  {this.props.fromOpen && (
                    <TableContext.Consumer>
                      {({ state: { from_time }, handleDateChange }) => {
                        return (
                          <DateSelector
                            id="from_time"
                            label={this.props.fromLabel || "From"}
                            value={from_time}
                            onChange={handleDateChange}
                            dateFormat={this.props.dateFormat}
                            timeFormat={this.props.timeFormat}
                          />
                        );
                      }}
                    </TableContext.Consumer>
                  )}
                  {searchOpen && (
                    <SearchBar
                      onChange={(e) => onChange("search", e.target.value)}
                      value={search}
                    />
                  )}
                  <Profile />
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

MyAppBar.defaultProps = {
  dateFilter: false,
  searchOpen: false,
};

MyAppBar.propTypes = {
  fromTime: PropTypes.instanceOf(moment),
  toTime: PropTypes.instanceOf(moment),
  search: PropTypes.string,
  dateFilter: PropTypes.bool,
  searchOpen: PropTypes.bool,
  onChange: PropTypes.func,
  onDateChange: PropTypes.func,
};

export default withStyles(useStyles)(MyAppBar);

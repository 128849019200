import React, { Component } from "react";
import {
  handleResponse,
  success,
  warn,
} from "components/notifications/handleResponse";
import { createSoftware } from "api/software";
import DialogForm from "hoc/dialogForm";
import { Add } from "./Add";
import PropTypes from "prop-types";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.handleChild = React.createRef();

    this.state = {
      data: {
        name: "",
        version: "",
        description: "",
        category: { value: "vms", label: "VMS" },
        path: "",
      },
    };
  }
  componentDidMount() {
    if (this.props.data) {
      const { name, version, category, description, url } = this.props.data;
      this.setState({
        data: {
          ...this.state.data,
          name,
          version,
          category,
          description,
          url,
        },
      });
    }
  }

  multiselectChange = (e) => {
    const { data } = this.state;
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };

  handleChange = (e) => {
    let { data } = this.state;
    let { name, value } = e.target;
    data[name] = value;
    this.setState({ data });
  };

  onSubmit = () => {
    const {
      data: { name, version, description, category, path },
    } = this.state;
    this.setState({
      file: this.handleChild.current.requestParams(),
    });
    const file = this.handleChild.current.requestParams();
    if (!file && !path) {
      warn("Please Select the file");
      return;
    }

    const form = new FormData();
    if (file) {
      form.append("file", file);
    }
    if (path) {
      form.append("apk_url", path);
    }
    form.append("name", name);
    form.append("version", version);
    form.append("description", description);
    form.append("category", category?.value);

    this.handleChild.current._btn(false);
    this.props.submitClick();
    createSoftware(form)
      .then(({ data }) => {
        success(data);
        this.props.handleSubmitSuccess();
        this.props.submitClick();
      })
      .catch((error) => {
        handleResponse(error);
        this.props.submitClick();
      });
  };

  render() {
    const { handleChange, multiselectChange, handleChild } = this;

    const { data } = this.state;
    const formProps = {
      handleChange,
      multiselectChange,
      ...data,
      handleChild,
    };
    return (
      <React.Fragment>
        <Add {...formProps} />
      </React.Fragment>
    );
  }
}
CRUD.propTypes = {
  data: PropTypes.object,
  submitClick: PropTypes.func,
  handleSubmitSuccess: PropTypes.func,
};
export default DialogForm("Software", 640)(CRUD);

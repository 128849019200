import makeRequest from ".";

export function peopleCountTiles() {
  return makeRequest({
    uri: `/api/v1/people_counts/tiles`,
  });
}

export function peopleCountGraph() {
  return makeRequest({
    uri: `/api/v1/people_counts/recent_records`,
  });
}

export function peopleCountCamera() {
  return makeRequest({
    uri: `/api/v1/cameras?&type=PeopleCountCamera`,
  });
}

import React from "react";
import { Col } from "antd";
import MultiSelect from "components/inputs/Multiselect";
import SimpleTextInput from "components/inputs/simpleTextInput";

function Form({ block, handleChange, message, floor, blockData, floorData }) {
  return (
    <React.Fragment>
      <Col xs={12} sm={12} md={12} style={{ padding: "5px 10px" }}>
        <MultiSelect
          heading={"Block"}
          selected={block}
          options={blockData}
          handleChange={handleChange}
          name={"block"}
        />
      </Col>
      {block.value && block.value !== "all_block" && (
        <Col xs={12} sm={12} md={12} style={{ padding: "5px 10px" }}>
          <MultiSelect
            heading={"Floor"}
            selected={floor}
            options={floorData}
            handleChange={handleChange}
            name={"floor"}
            defaultValue={floorData[0]}
          />
        </Col>
      )}
      <Col item xs={24} sm={24} md={24} style={{ padding: "5px 10px" }}>
        <SimpleTextInput
          labelText="Message"
          name="message"
          value={message}
          onChange={handleChange}
          required
          multi
          rows={2}
        />
      </Col>
    </React.Fragment>
  );
}
export default Form;

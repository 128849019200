import React from "react";
import { formatTime, presence, titleize } from "helpers";

export const staffColumn = [
  {
    title: "Device Type",
    align: "center",
    render: (_, row) => {
      return <div>{presence(titleize(row.device_name))}</div>;
    },
  },
  {
    title: "In time",
    align: "center",
    render: (_, row) => {
      return <div>{presence(formatTime(row.in_time))}</div>;
    },
  },
];

export const invitesColumn = [
  {
    title: "Name / Phone",
    align: "center",
    render: (_, row) => {
      const name = row.invitees?.map((data) => data.name);
      const phone = row.invitees?.map((data) => data.phone);
      return (
        <>
          <div>{presence(titleize(name.toString()))}</div>
          <strong>{presence(phone)}</strong>
        </>
      );
    },
  },

  {
    title: "Invite Created",
    align: "center",
    dataIndex: "block",
    render: (created_at) => `${formatTime(created_at)}`,
  },
  {
    title: "Visiting time",
    align: "center",
    dataIndex: "visiting_time",
    render: (visiting_time) => `${formatTime(visiting_time)}`,
  },

  {
    title: "Number Plate",
    align: "center",
    render: (_, row) => {
      const numberPlate = row.invitees?.map((data) => data.vehicle_number);
      if (numberPlate[0]) {
        return (
          <>
            <div>{numberPlate}</div>
          </>
        );
      } else {
        return <span>-</span>;
      }
    },
  },

  {
    title: "Status",
    align: "center",
    render: (_, row) => {
      const status = row?.invitees?.map((data) => data.status);
      return (
        <>
          <div>{presence(titleize(status.toString()))}</div>
        </>
      );
    },
  },
];

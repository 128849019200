import React from "react";
import { presence, titleize } from "helpers";
import { Button } from "antd";
import { StatusComp } from "data/index";

export const columns = (editStaffs, aboutStaff) => {
  return [
    {
      title: "Name",
      align: "center",
      width: "40%",
      render: (row) => {
        return `${presence(row.name)}`;
      },
    },

    {
      title: "Phone",
      dataIndex: "phone",
      width: "20%",
      render: (phone) => {
        return `${presence(phone)}`;
      },
      align: "center",
    },
    {
      title: "Group",
      dataIndex: "department",
      width: "17%",
      render: (department) => {
        return `${presence(department)}`;
      },
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (status) => <StatusComp value={titleize(status)} />,
    },
    {
      title: "Manage",
      align: "center",
      render: (row) => {
        return (
          <React.Fragment>
            <div>
              <Button
                onClick={() => {
                  editStaffs(row);
                }}
                size="medium"
                style={{
                  margin: "0px 10px 5px 10px",
                }}
              >
                Edit
              </Button>
              <Button
                style={{
                  cursor: "pointer",
                }}
                onClick={() => aboutStaff(row)}
              >
                Manage
              </Button>
            </div>
          </React.Fragment>
        );
      },
    },
  ];
};

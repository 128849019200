import React from "react";
import "antd/dist/antd.css";
import { softwareType } from "config";
import { Input, Row, Col } from "antd";
import Select from "react-select";
import SimpleTextInput from "components/inputs/simpleTextInput";
import UploadFile from "views/UploadFile";

const { TextArea } = Input;
export const Add = ({
  category,
  handleChange,
  multiselectChange,
  handleChild,
  fileFormat,
  url,
}) => {
  const urlValue = url.length > 0;

  return (
    <React.Fragment>
      <Row>
        <Col xs={8} sm={8} md={8} style={{ padding: "10px" }}>
          <SimpleTextInput
            labelText="Name"
            name="name"
            onChange={handleChange}
            required
          />
        </Col>
        <Col xs={8} sm={8} md={8} style={{ padding: "10px" }}>
          <SimpleTextInput
            labelText="Version"
            name="version"
            onChange={handleChange}
            required
          />
        </Col>
        <Col xs={8} sm={8} md={8} style={{ padding: "10px" }}>
          <p
            style={{
              fontSize: 10,
              margin: "-0.1em 0 0.3em",
              color: "rgba(0, 0, 0, 0.54)",
            }}
          >
            {"Category"}
          </p>
          <Select
            value={category}
            options={softwareType}
            onChange={(value) => {
              multiselectChange({
                target: { value: value || [], name: "category" },
              });
            }}
            style={{ margin: "-5px 1px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} style={{ padding: "10px" }}>
          <TextArea
            placeholder="Description"
            rows={2}
            autoComplete="off"
            name="description"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} style={{ padding: "10px" }}>
          <div className="dropbox">
            <UploadFile
              ref={handleChild}
              accept={fileFormat || ".apk"}
              {...{
                title: "",
                uploadButton: false,
                urlValue: urlValue,
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} style={{ padding: "10px" }}>
          <SimpleTextInput
            labelText="Path"
            name="path"
            required={false}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};
